import { Component } from "@angular/core";
import { ComingSoonComponent } from "../empty-page/coming-soon.component";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { NgFor, NgIf } from "@angular/common";
import { MatCardModule } from "@angular/material/card";
import { MatSelectModule } from "@angular/material/select";
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { LocalizationModule } from "src/app/components/localization";
import { MatRadioModule } from "@angular/material/radio";
import { MatDialogRef } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";

@Component({
  selector: "app-medical-note-dialog",
  templateUrl: "./medical-note-dialog.component.html",
  standalone: true,
  imports: [
    LocalizationModule,
    ComingSoonComponent,
    MatSidenavModule,
    NgFor,
    MatSidenavModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    NgFor,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatSelectModule,
    MatIconModule,
    MatRadioModule,
  ],
  styleUrls: ["./medical-note-dialog.component.scss"],
})
export class MedicalNoteDialogComponent {
  notifyOptions: string[] = ["All", "Doctors", "Nurses"];
  areas: string[] = ["Area #1", "Area #2", "Area #3"];

  form: FormGroup;
  success: boolean = false;

  public interestOptions = [
    { id: "athleteTracking", label: "Athlete tracking and registration" },
    { id: "studioManagement", label: "Studio management" },
    { id: "eventManagement", label: "Event management" },
    { id: "memberships", label: "Memberships" },
    { id: "federation", label: "Federation or franchise management" },
    { id: "ticketSales", label: "Ticket sales" },
    { id: "banquetsAndSeminars", label: "Banquets and seminars" },
  ];
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<MedicalNoteDialogComponent>
  ) {
    this.form = this.fb.group({
      name: ["", [Validators.required]],
      email: ["", [Validators.required]],
      interest: ["", [Validators.required]],
      message: ["", Validators.required],
    });
  }
  onSubmit() {
    if (this.form.valid) {
      // Process form data here
      const interestId = this.form.value.interest;
      let interestValue = this.interestOptions
        .filter((value) => value.id === this.form.value.interest)
        .pop();

      let joinUserData = {
        name: this.form.value.name,
        email: this.form.value.email,
        message: this.form.value.message,
        interest: interestValue?.label,
      };
      console.log("Form Submitted", this.form.value, interestValue);
      this.success = true;
    } else {
      // Mark all fields as touched to show errors
      this.form.markAllAsTouched();
    }
  }

  isFieldInvalid(field: string): boolean | undefined {
    return (
      this.form.get(field)?.invalid &&
      (this.form.get(field)?.touched || this.form.get(field)?.dirty)
    );
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
