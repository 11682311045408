import { CommonModule, Location, NgTemplateOutlet } from "@angular/common";
import { Component, NgModule, OnDestroy, OnInit } from "@angular/core";
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterModule,
} from "@angular/router";
import { LocalizationModule } from "src/app/components/localization";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from "@angular/forms";
import { EventService } from "../../shared/services/event.service";
import * as moment from "moment";
import { Role } from "../../shared/models/role.model";
import {
  DashboardAdminOptions,
  Privileges,
} from "../../shared/constants/privileges.enum";
import { ClickStopPropagation } from "../../shared/directives/click-stop-propagation.directive";
import { environment } from "../../../environments/environment";
import { UserState } from "../../store/user/user.store";
import { Select, Store } from "@ngxs/store";
import { Observable, Subject } from "rxjs";
import { NgSelectModule } from "@ng-select/ng-select";
import { EventsService } from "../../shared/constants/events.service";
import { User } from "../../shared/models/user.model";
import { first, takeUntil } from "rxjs/operators";
import { SetSelectedEvent } from "../../store/user/user.actions";
import { MatDialog } from "@angular/material/dialog";
import { CallMedicDialogComponent } from "src/app/components/call-medic-dialog/call-medic-dialog.component";
import { MedicalDashboardComponent } from "../../components/medical-dashboard/medical-dashboard.component";
import { MedicalNoteDialogComponent } from "src/app/components/medical-note-dialog/medical-note-dialog.component";
import { SearchableCustomSelectComponent } from "src/app/components/searchable-custom-select/searchable-custom-select.component";
import { EventsSelectorService } from "src/app/shared/services/events-selector.service";
import { Ring } from "../../shared/models/rings.model";
import { MedicPerson } from "../../shared/models/events.model";
import { TranslocoService } from "@ngneat/transloco";
import {ToastrService} from "ngx-toastr";

enum DashboardType {
  dashboard = "dashboard",
  admin = "admin",
  event = "event",
  league = "league",
  medical = "medical",
  adminFinances = "adminFinances",
  adminAccounts = "adminAccounts",
  eventManagement = "eventManagement",
  accountHolder = "accountHolder",
  eventManagementSchedule = "eventManagementSchedule",
  eventReports = "eventReports",
  eventReportsFinance = "eventReportsFinance",
  eventReportsCompetitor = "eventReportsCompetitor",
  eventReportsResults = "eventReportsResults",
  eventReportsSchool = "eventReportsSchool",
  eventReportsMedical = "eventReportsMedical",
  eventReportsStaff = "eventReportsStaff",
  eventReportsPrint = "eventReportsPrint",
  eventSettings = "eventSettings",
  eventSettingsStaffManagement = "eventSettingsStaffManagement",
}

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    LocalizationModule,
    RouterModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    ClickStopPropagation,
    NgSelectModule,
    NgTemplateOutlet,
    MedicalDashboardComponent,
    SearchableCustomSelectComponent,
  ],
})
export class DashboardComponent implements OnInit, OnDestroy {
  @Select(UserState.authUser)
  public authUser$!: Observable<User>;

  @Select(UserState.isAdmin)
  isAdmin$!: Observable<boolean>;

  user: any;

  public isAdmin: boolean | null = null;

  private unsubscriber$ = new Subject();

  type: DashboardType = DashboardType.dashboard;

  protected readonly DashboardAdminOptions = DashboardAdminOptions;

  protected readonly Privileges = Privileges;

  breadcrumbs: any;

  previousUrl: any;

  public eventsControl = new FormControl(null);

  public eventsFormGroup = new FormGroup({
    events: this.eventsControl,
    eventSelector: new FormControl(null),
  });

  public leaguesFormGroup = new FormGroup({
    league: new FormControl(null),
    leagueSearch: new FormControl(null),
  });

  events: any;

  private searchTimer: any;

  selectedEvent: any = null;
  eventRoles: Role[] = [];
  eventConfigItems: any = {};
  eventStaffMap: any = {};
  eventRings: Ring[] = [];
  eventPrivileges: Privileges[] = [];

  leagues: any;
  selectedLeague: any = null;
  isLeaguesAvailable = false;

  eventManagementPrivileges: Privileges[] = [
    Privileges.REGISTRATION,
    Privileges.CHECK_IN,
    Privileges.SELL_TICKETS,
    Privileges.RINGS,
    Privileges.SCOREKEEPER,
    Privileges.CHIEF_JUDGE,
    Privileges.PRICING_PLAN,
    Privileges.SEATING_CHART,
    Privileges.SEND_TICKETS,
    Privileges.EVENT_EDITOR,
    Privileges.JUDGE,
    Privileges.EVENT_STREAMING,
  ];

  settingsPrivileges: Privileges[] = [
    Privileges.STAFF_MANAGEMENT,
    Privileges.STAFF_QR_CODES,
    Privileges.ROLES_APPROVAL,
    Privileges.EDIT_DIVISIONS,
    Privileges.FLOWS,
    Privileges.DISCOUNT_CODE,
    Privileges.BUILD_DRAWS,
    Privileges.SHOW_DRAWS,
    Privileges.EVENT_IMAGES,
  ];

  reportsPrivileges: Privileges[] = [
    Privileges.CERTIFICATION_REPORT,
    Privileges.STANDING_REPORT,
    Privileges.FINANCIAL_REPORT,
    Privileges.POINT_CORRECTION,
    Privileges.AUTHORIZED_SCHOOL_OWNER,
    Privileges.EVENT_PARTICIPANT,
    Privileges.DIVISIONS_PRIZES_REPORT,
    Privileges.COMPETITOR_REPORT,
    Privileges.MERCHANDISE_REPORT,
    Privileges.MEMBERSHIP_REPORT,
    Privileges.SCHOOLS_REPORT,
    Privileges.GEO_REPORT,
    Privileges.RINGS_REPORTS,
    Privileges.TOURNAMENT_PERFORMANCE,
    Privileges.STAFF_REPORT,
    Privileges.STAFF_ACTIVITY,
    Privileges.ADDED_COMPETITORS,
    Privileges.RESTRICTIONS_REPORT,
    Privileges.MEDICAL_FORM,
    Privileges.MEDICAL_REPORT,
    Privileges.PRINT_ENVELOPES,
    Privileges.ACCREDITATION_CARDS,
    Privileges.LIMITS_REPORT,
    Privileges.PARTICIPANT_REPORT,
    Privileges.PARTIAL_PAYMENT_REPORT,
  ];

  actionsSearchFormGroup: FormGroup;

  headerAdminActions: any;

  actionsArray: any[] = [];
  adminRowActions: any = [];

  adminRowAccountActions: any = [];

  adminRowFinancesActions: any = [];
  headerEventActions: any = [];

  eventViewActions: any = [];
  eventmanagementActions: any = [];
  eventManagementScheduleActions: any = [];
  eventReportsActions: any = [];
  eventReportsFinanceActions: any = [];
  eventReportsCompetitorActions: any = [];
  eventReportsResultsActions: any = [];
  eventReportsSchoolActions: any = [];
  eventReportsMedicalActions: any = [];
  eventReportsStaffActions: any = [];
  eventReportsPrintActions: any = [];
  eventSettingsActions: any = [];
  eventSettingsStaffManagementActions: any = [];
  accountHoldersActions: any = [];
  leagueActions: any = [];

  actionItems: {
    id?: number;
    title: string;
    img: string;
    url: string | null;
    routerLink: string | null;
    optRouterLink: string | null;
    privilege: any;
    leaguePrivilege: any;
  }[] = [];
  filteredActionItems = this.actionItems;

  showDropdown = false;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public location: Location,
    private transloco: TranslocoService,
    private toaster: ToastrService,
    private eventService: EventService,
    private store: Store,
    private eventsService: EventsService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private eventsSelectorService: EventsSelectorService
  ) {
    this.router.events.pipe(takeUntil(this.unsubscriber$)).subscribe((r) => {
      if (r instanceof NavigationEnd) {
        this.parseUrl(r.url);
        this.updateBreadcrumbs();
      }
    });

    this.actionsSearchFormGroup = this.fb.group({
      actionSearch: [""],
    });
  }

  ngOnInit(): void {
    this.eventsFormGroup.controls.events.valueChanges.subscribe(
      (eventId: any) => {
        this.loadEventData(eventId);
      }
    );

    this.eventsFormGroup.controls.eventSelector.valueChanges.subscribe(
      (event: any) => {
        if (event) {
          this.loadEventData(event.id);
        } else {
          this.setSelectedEvent(null);
          this.eventRoles = [];
          this.processEventPrivileges([]);
        }
      }
    );

    this.authUser$.subscribe((user: any) => {
      if (user && (!this.user || user.id !== this.user.id)) {
        this.user = user;

        this.isAdmin = this.store.selectSnapshot(UserState.isAdmin);
        this.fetchEventsData();

        if (!this.isAdmin) {
          if (
            user &&
            user.eventAccount &&
            user.eventAccount.accessLevel &&
            user.eventAccount.accessLevel === "USER"
          ) {
            // is an account holder user
            const actualUrl = this.router.url;
            const accountHolderUrl = "/dashboard/event/account-holder";
            if (actualUrl !== accountHolderUrl) {
              this.router.navigateByUrl(accountHolderUrl);
            }
          }
        }

        if (this.doesUserHaveLeaguePrivileges(user) && this.isLeagueViews) {
          this.getUserLeagues();
        }

        this.initializeSearchActions();
      }
    });
  }

  executeAction(action: {
    id?: number;
    title: string;
    img: string;
    url: string | null;
    routerLink: string | null;
    privilege: any;
    optRouterLink: string | null;
    leaguePrivilege: any;
  }) {
    if (action.id && action.id === 1) {
      window.location.href = this.getRegistrationDashboardLink();
    } else if (action.id && action.id === 2) {
      window.location.href = this.getHrefForLeagueParticipantReport();
    } else if (action.routerLink) {
      this.router.navigateByUrl(action.routerLink);
    } else if (action.url) {
      window.location.href = action.url;
    } else if (action.privilege) {
      const newUrl = this.getHrefForEventPrivilege(action.privilege);
      if (newUrl) {
        window.location.href = newUrl;
      } else if (action.optRouterLink) {
        this.router.navigateByUrl(action.optRouterLink);
      }
    } else if (action.leaguePrivilege) {
      const newUrl = this.getHrefForLeaguePrivilege(action.leaguePrivilege);
      if (newUrl) {
        window.location.href = newUrl;
      }
    }
  }

  initializeSearchActions() {
    this.headerAdminActions = [
      {
        routerLink: null,
        option: DashboardAdminOptions.leagueEmails,
        icon: "/assets/icons/dashboard/ic-send-email.svg",
        alt: "email",
        label: "SEND_EMAILS",
        privilge: null,
      },
      {
        routerLink: null,
        option: DashboardAdminOptions.zoomMeetings,
        icon: "/assets/icons/dashboard/ic-zoom-meetings.svg",
        alt: "zoom meetings",
        label: "ZOOM_MEETINGS",
      },
      {
        option: DashboardAdminOptions.ads,
        icon: "/assets/icons/dashboard/ic-ads.svg",
        alt: "advertisement",
        label: "ADVERTISEMENT",
      },
      {
        option: DashboardAdminOptions.adminDemoPage,
        icon: "/assets/icons/dashboard/ic-demo.svg",
        alt: "demo pages",
        label: "DEMO_PAGES",
      },
      {
        option: DashboardAdminOptions.helpFilesConstructor,
        icon: "/assets/icons/dashboard/ic-help.svg",
        alt: "help files",
        label: "HELP_FILES",
      },
    ];

    this.adminRowActions = [
      {
        option: DashboardAdminOptions.createEvent,
        icon: "/assets/icons/dashboard/ic-create-event.svg",
        alt: "create event",
        label: "CREATE_EVENT",
        privilege: null,
      },
      {
        option: DashboardAdminOptions.eventWizard,
        icon: "/assets/icons/dashboard/ic-event-wizard.svg",
        alt: "event wizard",
        label: "EVENT_WIZARD",
        privilege: null,
      },
      {
        option: DashboardAdminOptions.adminScoreboardConfig,
        icon: "/assets/icons/dashboard/ic-scoreboard-config.svg",
        alt: "scoreboard configuration ",
        label: "SCOREBOARD_CONFIGURATION",
        privilege: null,
      },
      {
        option: DashboardAdminOptions.merchandise,
        icon: "/assets/icons/dashboard/ic-merchandise.svg",
        alt: "merchandise",
        label: "MERCHANDISE_TICKETS",
        privilege: null,
      },
      {
        option: DashboardAdminOptions.adminAllSchools,
        icon: "/assets/icons/dashboard/ic-studios.svg",
        alt: "merchandise",
        label: "STUDIOS",
        privilege: null,
      },
      {
        routerLink: "/dashboard/admin/accounts",
        option: null,
        icon: "/assets/icons/dashboard/ic-accounts-roles.svg",
        alt: "account roles",
        label: "ACCOUNTS_ROLES",
        privilege: null,
      },
      {
        routerLink: "/dashboard/admin/finances",
        option: null,
        icon: "/assets/icons/dashboard/ic-finances.svg",
        alt: "finances",
        label: "FINANCES",
        privilege: null,
      },
      {
        option: DashboardAdminOptions.adminRatings,
        icon: "/assets/icons/dashboard/ic-ratings.svg",
        alt: "leagues and ratings",
        label: "LEAGUES_RATINGS",
        privilege: null,
      },
      {
        option: DashboardAdminOptions.leaguePages,
        icon: "/assets/icons/dashboard/ic-leagues-pages.svg",
        alt: "leagues pages",
        label: "LEAGUE_PAGES",
        privilege: null,
      },
      {
        option: DashboardAdminOptions.adminEntity,
        icon: "/assets/icons/dashboard/ic-entities.svg",
        alt: "entities management",
        label: "ENTITIES_MANAGEMENT",
        privilege: null,
      },
      {
        option: DashboardAdminOptions.events,
        icon: "/assets/icons/dashboard/ic-events.svg",
        alt: "events report",
        label: "EVENTS_REPORT",
        privilege: null,
      },
    ];

    this.adminRowAccountActions = [
      {
        routerLink: null,
        option: DashboardAdminOptions.searchRegistration,
        icon: "/assets/icons/dashboard/ic-search-reg.svg",
        alt: "Search registration",
        label: "SEARCH_REGISTRATION",
        privilege: null,
      },
      {
        routerLink: null,
        option: DashboardAdminOptions.myResults,
        icon: "/assets/icons/dashboard/ic-person-results.svg",
        alt: "Person results",
        label: "PERSONS_RESULTS",
        privilege: null,
      },
      {
        routerLink: null,
        option: DashboardAdminOptions.adminRolesApproval,
        icon: "/assets/icons/dashboard/ic-roles-approval.svg",
        alt: "Roles approval",
        label: "ROLES_APPROVAL",
        privilege: null,
      },
      {
        routerLink: null,
        option: DashboardAdminOptions.adminRoles,
        icon: "/assets/icons/dashboard/ic-roles-management.svg",
        alt: "Roles management",
        label: "ROLES_MANAGEMENT",
        privilege: null,
      },
      {
        routerLink: null,
        option: DashboardAdminOptions.adminEventPromoter,
        icon: "/assets/icons/dashboard/ic-account-management.svg",
        alt: "Accounts management",
        label: "ACCOUNTS_MANAGEMENT",
        privilege: null,
      },
      {
        routerLink: null,
        option: DashboardAdminOptions.mergePerson,
        icon: "/assets/icons/dashboard/ic-merge-person.svg",
        alt: "Merge person",
        label: "MERGE_PERSON",
      },
      {
        routerLink: null,
        option: DashboardAdminOptions.adminPasswordReset,
        icon: "/assets/icons/dashboard/ic-password-reset.svg",
        alt: "Password reset",
        label: "PASSWORD_RESET",
        privilege: null,
      },
      {
        routerLink: null,
        option: DashboardAdminOptions.adminUserSession,
        icon: "/assets/icons/dashboard/ic-user-session.svg",
        alt: "User session",
        label: "USER_SESSION",
        privilege: null,
      },
    ];

    this.adminRowFinancesActions = [
      {
        routerLink: null,
        option: DashboardAdminOptions.adminPromoterAccounts,
        icon: "/assets/icons/dashboard/ic-promoter-accounts.svg",
        alt: "Promoter accounts",
        label: "PROMOTER_ACCOUNTS",
        privilege: null,
      },
      {
        routerLink: null,
        option: DashboardAdminOptions.affiliateReport,
        icon: "/assets/icons/dashboard/ic-affiliates-report.svg",
        alt: "Affiliates report",
        label: "AFFILIATES_REPORT",
        privilege: null,
      },
      {
        routerLink: null,
        option: DashboardAdminOptions.paymentMethodReport,
        icon: "/assets/icons/dashboard/ic-event-pay-methods.svg",
        alt: "Event payment methods",
        label: "EVENT_PAYMENT_METHODS",
        privilege: null,
      },
      {
        routerLink: null,
        option: DashboardAdminOptions.billing,
        icon: "/assets/icons/dashboard/ic-billing.svg",
        alt: "Billing",
        label: "BILLING",
        privilege: null,
      },
      {
        routerLink: null,
        option: DashboardAdminOptions.adminBalance,
        icon: "/assets/icons/dashboard/ic-balance-report.svg",
        alt: "Balance report",
        label: "BALANCE_REPORT",
        privilege: null,
      },
      {
        routerLink: null,
        option: DashboardAdminOptions.adminPromoterInvoice,
        icon: "/assets/icons/dashboard/ic-invoice-submission.svg",
        alt: "Invoice submission",
        label: "INVOICE_SUBMISSION",
        privilege: null,
      },
      {
        routerLink: null,
        option: DashboardAdminOptions.adminInvoiceManagement,
        icon: "/assets/icons/dashboard/ic-invoice-management.svg",
        alt: "Invoice management",
        label: "INVOICE_MANAGEMENT",
        privilege: null,
      },
    ];

    this.headerEventActions = [
      {
        id: 3,
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/management",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-my-invoices.svg",
        alt: "My invoices",
        label: "MY_INVOICES",
        privilege: Privileges.PROMOTER_INVOICE,
      },
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/management",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-find-competitors.svg",
        alt: "Find competitors",
        label: "FIND_COMPETITORS",
        privilege: Privileges.FIND_ME,
      },
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/management",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-find-standing.svg",
        alt: "Find standing",
        label: "FIND_STANDING",
        privilege: Privileges.FIND_STANDING,
      },
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/management",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-send-email.svg",
        alt: "Send emails",
        label: "SEND_EMAILS",
        privilege: Privileges.SEND_EMAILS,
      },
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/management",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-live-scoring.svg",
        alt: "Live scoring",
        label: "LIVE_SCORING",
        privilege: Privileges.RING_SCORING,
      },
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/management",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-missing-child-alert.svg",
        alt: "Missing child alert",
        label: "MISSING_CHILD_ALERT",
        privilege: Privileges.AMBER_ALERT,
      },
    ];

    this.eventViewActions = [
      {
        routerLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/management",
        option: null,
        icon: "/assets/icons/dashboard/ic-management.svg",
        alt: "Management",
        label: "MANAGEMENT",
        privilege: null,
      },
      {
        routerLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/reports",
        option: null,
        icon: "/assets/icons/dashboard/ic-reports.svg",
        alt: "Reports",
        label: "REPORTS",
        privilege: null,
      },
      {
        routerLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/settings",
        option: null,
        icon: "/assets/icons/dashboard/ic-settings.svg",
        alt: "Settings",
        label: "SETTINGS",
        privilege: null,
      },
    ];

    this.eventmanagementActions = [
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/management",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-rings.svg",
        alt: "RINGS",
        label: "RINGS",
        privilege: Privileges.RINGS,
      },
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/management",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-scorekeeper.svg",
        alt: "SCOREKEEPER",
        label: "SCOREKEEPER",
        privilege: Privileges.SCOREKEEPER,
      },
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/management",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-judge-clicker.svg",
        alt: "JUDGE CLICKER",
        label: "JUDGE_CLICKER",
        privilege: Privileges.JUDGE,
      },
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/management",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-chief-judge.svg",
        alt: "CHIEF JUDGE",
        label: "CHIEF_JUDGE",
        privilege: Privileges.CHIEF_JUDGE,
      },
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/management",
        admin: true,
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-uventex-tv.svg",
        alt: "UVENTEX TV",
        label: "UVENTEX_TV",
        privilege: Privileges.UVENTEX_TV,
      },
      {
        routerLink:
          "/dashboard/event" +
          this.getSelectedEventIdStrVal() +
          "/management/schedule",
        option: null,
        icon: "/assets/icons/dashboard/ic-schedule.svg",
        alt: "SCHEDULE",
        label: "SCHEDULE",
        privilege: null,
      },
      {
        routerLink:
          "/dashboard/event" +
          this.getSelectedEventIdStrVal() +
          "/management/medic-info",
        option: null,
        icon: "/assets/icons/dashboard/ic-medic-dashboard.svg",
        alt: "MEDIC DASHBOARD",
        label: "MEDIC_DASHBOARD",
        privilege: null,
      },
      {
        admin: true,
        routerLink: null,
        option: null,
        click: () => { this.deleteAllScores(); },
        icon: "/assets/icons/cancel.svg",
        alt: "DELETE ALL SCORES",
        label: "DELETE_ALL_SCORES",
        privilege: Privileges.EVENT_DELETE_ALL_SCORES,
      },
    ];

    this.eventManagementScheduleActions = [
      {
        optRouterLink:
          "/dashboard/event" +
          this.getSelectedEventIdStrVal() +
          "/management/schedule",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-build-schedule.svg",
        alt: "BUILD SCHEDULE",
        label: "BUILD_SCHEDULE",
        privilege: Privileges.BUILD_DRAWS,
      },
      // {
      //   routerLink: null,
      //   option: null,
      //   icon: "/assets/icons/dashboard/ic-timetable.svg",
      //   alt: "TIMETABLE",
      //   label: "TIMETABLE",
      //   privilege: null,
      // },
    ];

    this.eventReportsActions = [
      {
        routerLink:
          "/dashboard/event" +
          this.getSelectedEventIdStrVal() +
          "/reports/finance",
        option: null,
        icon: "/assets/icons/dashboard/ic-finance-reports.svg",
        alt: "FINANCE REPORTS",
        label: "FINANCE_REPORTS",
        privilege: null,
      },
      {
        routerLink:
          "/dashboard/event" +
          this.getSelectedEventIdStrVal() +
          "/reports/competitor",
        option: null,
        icon: "/assets/icons/dashboard/ic-competitor-report.svg",
        alt: "COMPETITOR REPORT",
        label: "COMPETITOR_REPORT",
        privilege: null,
      },
      {
        routerLink:
          "/dashboard/event" +
          this.getSelectedEventIdStrVal() +
          "/reports/results",
        option: null,
        icon: "/assets/icons/dashboard/ic-results-reports.svg",
        alt: "RESULTS REPORTS",
        label: "RESULTS_REPORTS",
        privilege: null,
      },
      {
        routerLink:
          "/dashboard/event" +
          this.getSelectedEventIdStrVal() +
          "/reports/school",
        option: null,
        icon: "/assets/icons/dashboard/ic-schools-reports.svg",
        alt: "SCHOOLS REPORTS",
        label: "SCHOOLS_REPORTS",
        privilege: null,
      },
      {
        routerLink:
          "/dashboard/event" +
          this.getSelectedEventIdStrVal() +
          "/reports/medical",
        option: null,
        icon: "/assets/icons/dashboard/ic-medical-reports.svg",
        alt: "MEDICAL REPORTS",
        label: "MEDICAL_REPORTS",
        privilege: null,
      },
      {
        routerLink:
          "/dashboard/event" +
          this.getSelectedEventIdStrVal() +
          "/reports/staff",
        option: null,
        icon: "/assets/icons/dashboard/ic-staff-reports.svg",
        alt: "STAFF REPORTS",
        label: "STAFF_REPORTS",
        privilege: null,
      },
      {
        routerLink:
          "/dashboard/event" +
          this.getSelectedEventIdStrVal() +
          "/reports/print",
        option: null,
        icon: "/assets/icons/dashboard/ic-print.svg",
        alt: "PRINT",
        label: "PRINT",
        privilege: null,
      },
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/reports",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-official-report.svg",
        alt: "OFFICIALS REPORT",
        label: "OFFICIALS_REPORT",
        privilege: Privileges.OFFICIALS_REPORT,
      },
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/reports",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-rings-report.svg",
        alt: "RINGS REPORTS",
        label: "RINGS_REPORTS",
        privilege: Privileges.RINGS_REPORTS,
      },
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/reports",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-geo-report.svg",
        alt: "GEO REPORT",
        label: "GEO_REPORT",
        privilege: Privileges.GEO_REPORT,
      },
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/reports",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-tournament-performance.svg",
        alt: "TOURNAMENT PERFORMANCE",
        label: "TOURNAMENT_PERFORMANCE",
        privilege: Privileges.TOURNAMENT_PERFORMANCE,
      },
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/reports",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-participant-report.svg",
        alt: "PARTICIPANT REPORT",
        label: "PARTICIPANT_REPORT",
        privilege: Privileges.PARTICIPANT_REPORT,
      },
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/reports",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-affiliates-report-2.svg",
        alt: "AFFILIATES REPORT",
        label: "AFFILIATES_REPORT",
        privilege: Privileges.AFFILIATES_REPORT,
      },
    ];

    this.eventReportsFinanceActions = [
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/reports",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-financial-report.svg",
        alt: "FINANCIAL REPORT",
        label: "FINANCIAL_REPORT",
        privilege: Privileges.FINANCIAL_REPORT,
      },
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/reports",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-merchandise-report.svg",
        alt: "MERCHANDISE REPORT",
        label: "MERCHANDISE_REPORT",
        privilege: Privileges.MERCHANDISE_REPORT,
      },
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/reports",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-membership-report.svg",
        alt: "MEMBERSHIP REPORT",
        label: "MEMBERSHIP_REPORT",
        privilege: Privileges.MEMBERSHIP_REPORT,
      },
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/reports",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-partial-payment-report.svg",
        alt: "PARTIAL PAYMENT REPORT",
        label: "PARTIAL_PAYMENT_REPORT",
        privilege: Privileges.PARTIAL_PAYMENT_REPORT,
      },
    ];

    this.eventReportsCompetitorActions = [
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/reports",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-competitor-report.svg",
        alt: "COMPETITOR REPORT",
        label: "COMPETITOR_REPORT",
        privilege: Privileges.COMPETITOR_REPORT,
      },
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/reports",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-event-participant.svg",
        alt: "EVENT PARTICIPANT",
        label: "EVENT_PARTICIPANT",
        privilege: Privileges.EVENT_PARTICIPANT,
      },
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/reports",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-added-competitors.svg",
        alt: "ADDED COMPETITORS",
        label: "ADDED_COMPETITORS",
        privilege: Privileges.ADDED_COMPETITORS,
      },
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/reports",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-restrictions-report.svg",
        alt: "RESTRICTIONS REPORT",
        label: "RESTRICTIONS_REPORT",
        privilege: Privileges.RESTRICTIONS_REPORT,
      },
    ];
    this.eventReportsResultsActions = [
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/reports",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-standings-report.svg",
        alt: "STANDINGS REPORT",
        label: "STANDINGS_REPORT",
        privilege: Privileges.STANDING_REPORT,
      },
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/reports",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-divisions-prizes-report.svg",
        alt: "DIVISIONS PRIZES REPORT",
        label: "DIVISIONS_PRIZES_REPORT",
        privilege: Privileges.DIVISIONS_PRIZES_REPORT,
      },
    ];

    this.eventReportsSchoolActions = [
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/reports",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-authorized-school-owner.svg",
        alt: "AUTHORIZED SCHOOL OWNER",
        label: "AUTHORIZED_SCHOOL_OWNER",
        privilege: Privileges.AUTHORIZED_SCHOOL_OWNER,
      },
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/reports",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-schools-report.svg",
        alt: "SCHOOLS REPORT",
        label: "SCHOOLS_REPORT",
        privilege: Privileges.SCHOOLS_REPORT,
      },
    ];

    this.eventReportsMedicalActions = [
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/reports",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-medical-form.svg",
        alt: "MEDICAL FORM",
        label: "MEDICAL_FORM",
        privilege: Privileges.MEDICAL_FORM,
      },
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/reports",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-medical-report.svg",
        alt: "MEDICAL REPORT",
        label: "MEDICAL_REPORT",
        privilege: Privileges.MEDICAL_REPORT,
      },
    ];

    this.eventReportsStaffActions = [
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/reports",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-staff-report.svg",
        alt: "STAFF REPORT",
        label: "STAFF_REPORT",
        privilege: Privileges.STAFF_REPORT,
      },
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/reports",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-staff-activity.svg",
        alt: "STAFF ACTIVITY",
        label: "STAFF_ACTIVITY",
        privilege: Privileges.STAFF_ACTIVITY,
      },
    ];
    this.eventReportsPrintActions = [
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/reports",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-accreditation-cards.svg",
        alt: "ACCREDITATION CARDS",
        label: "ACCREDITATION_CARDS",
        privilege: Privileges.ACCREDITATION_CARDS,
      },
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/reports",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-print-envelopes.svg",
        alt: "PRINT ENVELOPES",
        label: "PRINT_ENVELOPES",
        privilege: Privileges.PRINT_ENVELOPES,
      },
    ];

    this.eventSettingsActions = [
      {
        routerLink:
          "/dashboard/event" +
          this.getSelectedEventIdStrVal() +
          "/settings/staff-management",
        option: null,
        icon: "/assets/icons/dashboard/ic-staff-management.svg",
        alt: "STAFF MANAGEMENT",
        label: "STAFF_MANAGEMENT",
        privilege: null,
      },
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/settings",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-event-editor.svg",
        alt: "EVENT EDITOR",
        label: "EVENT_EDITOR",
        privilege: Privileges.EVENT_EDITOR,
      },
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/settings",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-divisions-editor.svg",
        alt: "DIVISIONS EDITOR",
        label: "DIVISIONS_EDITOR",
        privilege: Privileges.EDIT_DIVISIONS,
      },
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/settings",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-pricing-plan.svg",
        alt: "PRICING PLAN",
        label: "PRICING_PLAN",
        privilege: Privileges.PRICING_PLAN,
      },
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/settings",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-discount-code.svg",
        alt: "DISCOUNT CODE",
        label: "DISCOUNT_CODE",
        privilege: Privileges.DISCOUNT_CODE,
      },
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/settings",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-flows.svg",
        alt: "FLOWS",
        label: "FLOWS",
        privilege: Privileges.FLOWS,
      },
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/settings",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-seating-chart.svg",
        alt: "SEATING CHART",
        label: "SEATING_CHART",
        privilege: Privileges.SEATING_CHART,
      },
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/settings",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-images.svg",
        alt: "IMAGES",
        label: "IMAGES",
        privilege: Privileges.EVENT_IMAGES,
      },
    ];

    this.eventSettingsStaffManagementActions = [
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/settings",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-roles.svg",
        alt: "ROLES",
        label: "ROLES",
        privilege: Privileges.STAFF_MANAGEMENT,
      },
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/settings",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-staff-qr-codes.svg",
        alt: "STAFF QR CODES",
        label: "STAFF_QR_CODES",
        privilege: Privileges.STAFF_QR_CODES,
      },
      {
        optRouterLink:
          "/dashboard/event" + this.getSelectedEventIdStrVal() + "/settings",
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-roles-approvals.svg",
        alt: "ROLES APPROVALS",
        label: "ROLES_APPROVALS",
        privilege: Privileges.ROLES_APPROVAL,
      },
    ];

    this.updateLeagueActions();

    const authToken = this.store.selectSnapshot(UserState.authToken);
    const token = authToken && authToken.token ? authToken.token : null;

    let returnUrl = encodeURIComponent(window.location.href).replaceAll(
      "%2F",
      "___"
    );

    this.accountHoldersActions = [
      {
        url: `${environment.EVENT_PORTAL_URL}#myDivisions;applicationMode=SPORTSHUB;sportsHubUrl=${returnUrl};token=${token}`,
        icon: "/assets/icons/dashboard/ic-ah-schedule.svg",
        alt: "SCHEDULE",
        label: "SCHEDULE",
      },
      {
        url: `${environment.EVENT_PORTAL_URL}#myOrder;applicationMode=SPORTSHUB;sportsHubUrl=${returnUrl};token=${token}`,
        icon: "/assets/icons/dashboard/ic-ah-orders.svg",
        alt: "ORDERS",
        label: "ORDERS",
      },
      {
        url: `${environment.EVENT_PORTAL_URL}#myResults;applicationMode=SPORTSHUB;sportsHubUrl=${returnUrl};token=${token}`,
        icon: "/assets/icons/dashboard/ic-results-reports.svg",
        alt: "RESULTS",
        label: "RESULTS",
      },
      {
        url: `${environment.EVENT_PORTAL_URL}#ringScoring;applicationMode=SPORTSHUB;sportsHubUrl=${returnUrl};token=${token}`,
        icon: "/assets/icons/dashboard/ic-ah-live-scoring.svg",
        alt: "LIVE SCORING",
        label: "LIVE_SCORING",
      },
      {
        url: `${environment.EVENT_PORTAL_URL}#mobilePass;applicationMode=SPORTSHUB;sportsHubUrl=${returnUrl};token=${token}`,
        icon: "/assets/icons/dashboard/ic-mobile-pass.svg",
        alt: "MOBILE PASS",
        label: "MOBILE_PASS",
      },
      {
        url: `${environment.EVENT_PORTAL_URL}#selfCheckIn;applicationMode=SPORTSHUB;sportsHubUrl=${returnUrl};token=${token}`,
        icon: "/assets/icons/dashboard/ic-check-in.svg",
        alt: "CHECK-IN",
        label: "CHECK-IN",
      },
      {
        url: `${environment.EVENT_PORTAL_URL}#mySchool;applicationMode=SPORTSHUB;sportsHubUrl=${returnUrl};token=${token}`,
        icon: "/assets/icons/dashboard/ic-studio.svg",
        alt: "STUDIO",
        label: "STUDIO",
      },
      {
        url: `${environment.EVENT_PORTAL_URL}#mySeminars;applicationMode=SPORTSHUB;sportsHubUrl=${returnUrl};token=${token}`,
        icon: "/assets/icons/dashboard/ic-seminars.svg",
        alt: "SEMINARS",
        label: "SEMINARS",
      },
    ];

    this.actionItems = [];

    const allActions: any = [
      ...this.headerAdminActions,
      ...this.adminRowActions,
      ...this.adminRowAccountActions,
      ...this.adminRowFinancesActions,
      ...this.headerEventActions,
      ...this.eventViewActions,
      ...this.eventmanagementActions,
      ...this.eventManagementScheduleActions,
      ...this.eventReportsActions,
      ...this.eventReportsFinanceActions,
      ...this.eventReportsCompetitorActions,
      ...this.eventReportsResultsActions,
      ...this.eventReportsSchoolActions,
      ...this.eventReportsMedicalActions,
      ...this.eventReportsStaffActions,
      ...this.eventReportsPrintActions,
      ...this.eventSettingsActions,
      ...this.eventSettingsStaffManagementActions,
      ...this.leagueActions,
      // we don't want this to be searchable ...this.accountHoldersActions,
    ];
    for (const action of allActions) {
      this.transloco
        .selectTranslate(action.label)
        .pipe(first())
        .subscribe((translation) => {
          if (
            !action.routerLink &&
            (!this.isAdmin || !action.id || action.id !== 3)
          ) {
            const url = action.option
              ? this.getAdminHrefForOption(action.option)
              : action.privilege
              ? this.getHrefForEventPrivilege(action.privilege)
              : null;
            const privilge = action.privilege ?? null;
            const leaguePrivilge = action.leaguePrivilege ?? null;
            const id = action.id ?? null;
            this.actionItems.push({
              id: id,
              title: translation,
              privilege: privilge,
              img: action.icon,
              url: url,
              leaguePrivilege: leaguePrivilge,
              routerLink: action.routerLink ?? null,
              optRouterLink: action.optRouterLink ?? null,
            });
          }
        });
    }

    this.transloco
      .selectTranslate("REGISTRATION_DASHBOARD")
      .pipe(first())
      .subscribe((translation) => {
        this.actionItems.push({
          id: 1,
          title: translation,
          privilege: null,
          img: "/assets/icons/dashboard/ic-registration-dashboard.svg",
          url: null,
          routerLink: null,
          leaguePrivilege: null,
          optRouterLink: null,
        });
      });

    if (this.selectedLeague) {
      this.transloco
        .selectTranslate("LEAGUE_PARTICIPANT_REPORT")
        .pipe(first())
        .subscribe((translation) => {
          this.actionItems.push({
            id: 2,
            title: translation,
            privilege: null,
            img: "/assets/icons/dashboard/ic-league-participant-report.svg",
            url: null,
            routerLink: null,
            leaguePrivilege: null,
            optRouterLink: null,
          });
        });
    }
    this.filteredActionItems = this.actionItems;
  }

  updateLeagueActions() {
    if (!this.selectedLeague) {
      this.leagueActions = [];
      return;
    }
    this.leagueActions = [
      {
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-clubs.svg",
        alt: "CLUBS",
        label: "CLUBS",
        leaguePrivilege: Privileges.EDIT_CLUBS,
      },
      {
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-foreign-clubs.svg",
        alt: "FOREIGN CLUBS",
        label: "FOREIGN_CLUBS",
        leaguePrivilege: Privileges.EDIT_CLUBS_FOREIGN,
      },
      {
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-competitors.svg",
        alt: "COMPETITORS",
        label: "COMPETITORS",
        leaguePrivilege: Privileges.EDIT_COMPETITORS,
      },
      {
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-foreign-competitors.svg",
        alt: "FOREIGN COMPETITORS",
        label: "FOREIGN_COMPETITORS",
        leaguePrivilege: Privileges.EDIT_COMPETITORS,
        foreign: true,
      },
      {
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-coaches.svg",
        alt: "COACHES",
        label: "COACHES",
        leaguePrivilege: Privileges.EDIT_COACHES,
      },
      {
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-foreign-coaches.svg",
        alt: "FOREIGN COACHES",
        label: "FOREIGN_COACHES",
        leaguePrivilege: Privileges.EDIT_COACHES,
        foreign: true,
      },
      {
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-regions.svg",
        alt: "REGIONS",
        label: "REGIONS",
        leaguePrivilege: Privileges.EDIT_REGIONS,
      },
      {
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-staff-management-2.svg",
        alt: "STAFF MANAGEMENT",
        label: "STAFF_MANAGEMENT",
        leaguePrivilege: Privileges.STAFF_CLUB_ADMINS,
      },
      {
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-id-cards.svg",
        alt: "ID CARDS",
        label: "ID_CARDS",
        leaguePrivilege: Privileges.LEAGUE_ID_CARDS,
      },
      {
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-event-wizard-2.svg",
        alt: "EVENT WIZARD",
        label: "EVENT_WIZARD",
        leaguePrivilege: Privileges.LEAGUE_EVENT_WIZARD,
      },
      {
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-send-certificates.svg",
        alt: "SEND CERTIFICATES",
        label: "SEND_CERTIFICATES",
        leaguePrivilege: Privileges.LEAGUE_SEND_CERTIFICATE,
      },
      {
        routerLink: null,
        option: null,
        icon: "/assets/icons/dashboard/ic-download-certificates.svg",
        alt: "DOWNLOAD CERTIFICATES",
        label: "DOWNLOAD_CERTIFICATES",
        leaguePrivilege: Privileges.LEAGUE_DOWNLOAD_CERTIFICATE,
      },
    ];
  }

  public ngOnDestroy() {
    //this.store.dispatch(new SetSelectedEvent(null));
    this.unsubscriber$.next(true);
    this.unsubscriber$.complete();
  }

  filterActions(): void {
    const searchTerm = this.actionsSearchFormGroup
      .get("actionSearch")
      ?.value.toLowerCase()
      .trim();

    this.filteredActionItems = this.actionItems.filter((item) =>
      item.title.toLowerCase().includes(searchTerm)
    );
  }

  hideDropdown(): void {
    // Timeout to allow click events on dropdown items before hiding
    setTimeout(() => (this.showDropdown = false), 200);
  }

  findClosestEvent(events: any) {
    if (!events || !(events.length > 0)) {
      return null;
    }

    let todayEvent: any = null;
    let upcomingEvent: any = null;

    const todayDate = Date.now();
    let startOfDay = new Date();
    startOfDay.setHours(0, 0, 0, 0);
    const todayStartOfDay = startOfDay.getTime();

    let endOfDay = new Date();
    endOfDay.setHours(23, 59, 59, 999);
    const todayEndofDay = endOfDay.getTime();

    events.forEach((event: any) => {
      const startDate = event.eventStartDate;

      if (
        startDate >= todayStartOfDay &&
        startDate < todayEndofDay &&
        todayEvent === null
      ) {
        todayEvent = event;
      }

      if (todayDate < startDate && upcomingEvent === null) {
        upcomingEvent = event;
      }
    });

    return todayEvent
      ? todayEvent
      : upcomingEvent
      ? upcomingEvent
      : events[events.length - 1];
  }

  public handleNavigationBack() {
    this.router.navigateByUrl(this.previousUrl ? this.previousUrl : "");
  }

  getEventDate(event: any) {
    if (event.eventStartDate === event.eventEndDate) {
      return moment(event.eventStartDate).format("MMMM D, YYYY");
    } else {
      const startMonth = new Date(event.eventStartDate).getMonth();
      const endMonth = new Date(event.eventEndDate).getMonth();
      const startYear = new Date(event.eventStartDate).getFullYear();
      const endYear = new Date(event.eventEndDate).getFullYear();

      if (startMonth === endMonth) {
        if (startYear === endYear) {
          return (
            moment(event.eventStartDate).format("MMMM D") +
            moment(event.eventEndDate).format("-D, YYYY")
          );
        } else {
          return (
            moment(event.eventStartDate).format("MMMM D, YYYY") +
            " - " +
            moment(event.eventEndDate).format("MMMM D, YYYY")
          );
        }
      } else {
        if (startYear === endYear) {
          return (
            moment(event.eventStartDate).format("MMMM D") +
            moment(event.eventEndDate).format(" - MMMM D, YYYY")
          );
        } else {
          return (
            moment(event.eventStartDate).format("MMMM D, YYYY") +
            " - " +
            moment(event.eventEndDate).format("MMMM D, YYYY")
          );
        }
      }
    }

    return null;
  }

  processEventPrivileges(roles: Role[]) {
    const privileges: Privileges[] = [];
    if (roles && roles.length > 0) {
      const length = roles.length;
      for (var i = 0; i < length; i++) {
        const role = roles[i];
        if (role.privileges && role.privileges.length > 0) {
          role.privileges.map((rolePrivilege) => {
            privileges.push(rolePrivilege.privilege);
          });
        }
      }
    }

    this.eventPrivileges = privileges;
  }

  isEventPrivilegeSet(privilege: Privileges) {
    if (!this.selectedEvent) {
      return false;
    }

    if (this.isAdmin) {
      return true;
    }

    return this.eventPrivileges.includes(privilege);
  }

  isRegistrationDashboardVisible() {
    if (this.isAdmin) {
      return true;
    }

    const privileges = this.eventPrivileges;
    let count = 0;

    if (privileges.includes(Privileges.REGISTRATION)) {
      count++;
    }

    if (privileges.includes(Privileges.CHECK_IN)) {
      count++;
    }

    if (privileges.includes(Privileges.SELL_TICKETS)) {
      count++;
    }

    return count > 0 && privileges.length > count;
  }

  isReportsAvailable() {
    if (!this.selectedEvent) {
      return false;
    }

    if (this.isAdmin) {
      return true;
    }

    const privileges = this.eventPrivileges;

    let available = false;
    const length = privileges ? privileges.length : 0;
    for (var i = 0; i < length; i++) {
      const privilege = privileges[i];
      if (this.reportsPrivileges.includes(privilege)) {
        available = true;
        break;
      }
    }

    return available;
  }

  isEventManagementAvailable() {
    if (!this.selectedEvent) {
      return false;
    }

    if (this.isAdmin) {
      return true;
    }

    const privileges = this.eventPrivileges;

    let available = false;
    const length = privileges ? privileges.length : 0;
    for (var i = 0; i < length; i++) {
      const privilege = privileges[i];
      if (this.eventManagementPrivileges.includes(privilege)) {
        available = true;
        break;
      }
    }

    return available;
  }

  isSettingsAvailable() {
    if (!this.selectedEvent) {
      return false;
    }

    if (this.isAdmin) {
      return true;
    }

    const privileges = this.eventPrivileges;

    let available = false;
    const length = privileges ? privileges.length : 0;
    for (var i = 0; i < length; i++) {
      const privilege = privileges[i];
      if (this.settingsPrivileges.includes(privilege)) {
        available = true;
        break;
      }
    }

    return available;
  }

  openCallMedicDialog(event: Event) {
    event.stopPropagation();

    let medicsListOptions: MedicPerson[] = [];
    medicsListOptions.push({ email: "", name: "All", personId: -1 });
    if (
      this.eventStaffMap &&
      this.eventStaffMap["MEDIC"] &&
      this.eventStaffMap["MEDIC"].length
    ) {
      for (let i = 0; i < this.eventStaffMap["MEDIC"].length; i++) {
        let person = this.eventStaffMap["MEDIC"][i];
        let medic: MedicPerson = {
          email: person.email,
          name: person.firstName + " " + person.lastName,
          personId: person.id,
        };
        medicsListOptions.push(medic);
      }
    }
    const dialogRef = this.dialog.open(CallMedicDialogComponent, {
      width: "400px",
      data: {
        medicsListOptions: medicsListOptions,
        eventRings: this.eventRings,
        eventId: this.selectedEvent.id,
        transloco: this.transloco,
      },
    });
  }

  openNotesDialog(event: Event) {
    event.stopPropagation();
    this.dialog.open(MedicalNoteDialogComponent, {
      width: "400px",
    });
  }

  getAdminHrefForOption(option: DashboardAdminOptions) {
    const authToken = this.store.selectSnapshot(UserState.authToken);
    const token = authToken && authToken.token ? authToken.token : null;
    let page = null;
    let params = "";

    if (option === DashboardAdminOptions.createEvent) {
      page = "adminEvent";
    } else if (option === DashboardAdminOptions.eventWizard) {
      page = "eventWizard";
    } else if (option === DashboardAdminOptions.adminScoreboardConfig) {
      page = "adminScoreboardConfig";
    } else if (option === DashboardAdminOptions.merchandise) {
      page = "merchandise";
    } else if (option === DashboardAdminOptions.zoomMeetings) {
      page = "zoomMeetings";
    } else if (option === DashboardAdminOptions.leagueEmails) {
      page = "leagueEmails";
    } else if (option === DashboardAdminOptions.liveStreamToken) {
      page = "liveStreamToken";
    } else if (option === DashboardAdminOptions.ads) {
      page = "ads";
    } else if (option === DashboardAdminOptions.adminDemoPage) {
      page = "adminDemoPage";
    } else if (option === DashboardAdminOptions.helpFilesConstructor) {
      page = "helpFilesConstructor";
    } else if (option === DashboardAdminOptions.adminRatings) {
      page = "adminRatings";
    } else if (option === DashboardAdminOptions.leaguePages) {
      page = "leaguePages";
    } else if (option === DashboardAdminOptions.adminEntity) {
      page = "adminEntity";
    } else if (option === DashboardAdminOptions.events) {
      page = "events";
    } else if (option === DashboardAdminOptions.searchRegistration) {
      page = "searchRegistration";
    } else if (option === DashboardAdminOptions.myResults) {
      page = "myResults";
    } else if (option === DashboardAdminOptions.adminRolesApproval) {
      page = "adminRolesApproval";
    } else if (option === DashboardAdminOptions.adminRoles) {
      page = "adminRoles";
    } else if (option === DashboardAdminOptions.adminEventPromoter) {
      page = "adminEventPromoter";
    } else if (option === DashboardAdminOptions.mergePerson) {
      page = "mergePerson";
    } else if (option === DashboardAdminOptions.adminPasswordReset) {
      page = "adminPasswordReset";
    } else if (option === DashboardAdminOptions.adminUserSession) {
      page = "adminUserSession";
    } else if (option === DashboardAdminOptions.adminPromoterAccounts) {
      page = "adminPromoterAccounts";
    } else if (option === DashboardAdminOptions.affiliateReport) {
      page = "affiliateReport";
    } else if (option === DashboardAdminOptions.paymentMethodReport) {
      page = "paymentMethodReport";
    } else if (option === DashboardAdminOptions.billing) {
      page = "billing";
    } else if (option === DashboardAdminOptions.adminBalance) {
      page = "adminBalance";
    } else if (option === DashboardAdminOptions.adminPromoterInvoice) {
      page = "adminPromoterInvoice";
    } else if (option === DashboardAdminOptions.adminInvoiceManagement) {
      page = "adminInvoiceManagement";
    } else if (option === DashboardAdminOptions.adminAllSchools) {
      page = "adminAllSchools";
    }

    let returnUrl = encodeURIComponent(window.location.href).replaceAll(
      "%2F",
      "___"
    );

    return `${environment.EVENT_ADMIN_URL}#${page};${params}applicationMode=SPORTSHUB;sportsHubUrl=${returnUrl};token=${token}`;
  }

  getHrefForEventPrivilege(privilege: Privileges) {
    let eventId = this.selectedEvent ? this.selectedEvent.id : null;
    if (!eventId) {
      return null;
    }

    const authToken = this.store.selectSnapshot(UserState.authToken);
    const token = authToken && authToken.token ? authToken.token : null;
    let page = null;
    let params = "";
    let id = "id";

    if (privilege === Privileges.EVENT_DELETE_ALL_SCORES) {
      return null;
    } else if (privilege === Privileges.PROMOTER_INVOICE) {
      page = "promoterInvoice";
      params = "register=0;";
    } else if (privilege === Privileges.FIND_ME) {
      page = "findMe";
    } else if (privilege === Privileges.FIND_STANDING) {
      page = "myStanding";
    } else if (privilege === Privileges.SEND_EMAILS) {
      page = "emails";
    } else if (privilege === Privileges.RING_SCORING) {
      page = "ringScoring";
    } else if (privilege === Privileges.AMBER_ALERT) {
      page = "amberAlert";
    } else if (privilege === Privileges.CALL_MEDIC) {
      page = "";
    } else if (privilege === Privileges.REGISTRATION) {
      page =
        this.selectedEvent?.eventConfig?.registrationPlaceToken ||
        "eventRegistration";
      eventId = this.selectedEvent.superEventId;
    } else if (privilege === Privileges.CHECK_IN) {
      page = "checkin";
    } else if (privilege === Privileges.SELL_TICKETS) {
      page = "sellTickets";
    } else if (privilege === Privileges.RINGS) {
      page = "rings";
    } else if (privilege === Privileges.STAFF_MANAGEMENT) {
      page = "staffManagement";
    } else if (privilege === Privileges.STAFF_QR_CODES) {
      page = "staffQrCodes";
    } else if (privilege === Privileges.ROLES_APPROVAL) {
      page = "adminEventApproval";
    } else if (privilege === Privileges.EDIT_DIVISIONS) {
      page = "adminDivisions";
    } else if (privilege === Privileges.FLOWS) {
      page = "flowsGymnastics";
    } else if (privilege === Privileges.DISCOUNT_CODE) {
      page = "discountCode";
    } else if (privilege === Privileges.BUILD_DRAWS) {
      page = "rebuildDraws";
    } else if (privilege === Privileges.SHOW_DRAWS) {
      page = "";
    } else if (privilege === Privileges.EVENT_IMAGES) {
      page = "adminEventPhoto";
    } else if (privilege === Privileges.SCOREKEEPER) {
      page = "scorekeeper";
      params = "register=0;";
    } else if (privilege === Privileges.CHIEF_JUDGE) {
      page = "chiefJudge";
      params = "register=0;";
    } else if (privilege === Privileges.PRICING_PLAN) {
      page = "adminEditEventPricingPlan";
    } else if (privilege === Privileges.SEATING_CHART) {
      page = "adminSeatingChart";
    } else if (privilege === Privileges.SEND_TICKETS) {
    } else if (privilege === Privileges.EVENT_EDITOR) {
      page = "adminEvent";
    } else if (privilege === Privileges.JUDGE) {
      page = "judge";
      params = "register=0;";
    } else if (privilege === Privileges.EVENT_STREAMING) {
      page = "eventStream";
    } else if (privilege === Privileges.CERTIFICATION_REPORT) {
    } else if (privilege === Privileges.STANDING_REPORT) {
      page = "standingReport";
    } else if (privilege === Privileges.FINANCIAL_REPORT) {
      page = "financialReportSuperEvent";
      eventId = this.selectedEvent.superEventId;
      params = "register=0;isNew=true;";
    } else if (privilege === Privileges.POINT_CORRECTION) {
      page = "pointCorrection";
    } else if (privilege === Privileges.AUTHORIZED_SCHOOL_OWNER) {
      page = "schoolFinancialReport";
    } else if (privilege === Privileges.EVENT_PARTICIPANT) {
      page = "adminEventParticipant";
    } else if (privilege === Privileges.DIVISIONS_PRIZES_REPORT) {
      page = "prizeReport";
    } else if (privilege === Privileges.COMPETITOR_REPORT) {
      page = "competitorReport";
    } else if (privilege === Privileges.MERCHANDISE_REPORT) {
      page = "merchandiseReport";
      eventId = this.selectedEvent.superEventId;
      params = "register=0;isNew=true;";
    } else if (privilege === Privileges.MEMBERSHIP_REPORT) {
      page = "membershipReport";
    } else if (privilege === Privileges.SCHOOLS_REPORT) {
      page = "schoolsReport";
    } else if (privilege === Privileges.GEO_REPORT) {
      page = "geoReport";
    } else if (privilege === Privileges.RINGS_REPORTS) {
      page = "ringsReports";
    } else if (privilege === Privileges.TOURNAMENT_PERFORMANCE) {
      page = "tournamentPerformance";
    } else if (privilege === Privileges.STAFF_REPORT) {
      page = "staffReport";
    } else if (privilege === Privileges.STAFF_ACTIVITY) {
      page = "staffActivityReport";
    } else if (privilege === Privileges.ADDED_COMPETITORS) {
      page = "addedCompetitorsReport";
    } else if (privilege === Privileges.RESTRICTIONS_REPORT) {
      page = "restrictionsReports";
    } else if (privilege === Privileges.MEDICAL_FORM) {
      page = "medicalFormReport";
    } else if (privilege === Privileges.MEDICAL_REPORT) {
      page = "medicalNoteReport";
    } else if (privilege === Privileges.PRINT_ENVELOPES) {
      page = "printEnvelopes";
    } else if (privilege === Privileges.ACCREDITATION_CARDS) {
      page = "accreditation";
    } else if (privilege === Privileges.LIMITS_REPORT) {
      page = "limitsReport";
    } else if (privilege === Privileges.PARTICIPANT_REPORT) {
      page = "participantReport";
    } else if (privilege === Privileges.PARTIAL_PAYMENT_REPORT) {
      page = "partialPaymentReport";
    } else if (privilege === Privileges.UVENTEX_TV) {
      page = "adminUventexTV";
    } else if (privilege === Privileges.OFFICIALS_REPORT) {
      page = "officialsReport";
    } else if (privilege === Privileges.AFFILIATES_REPORT) {
      page = "eventAffiliate";
    }

    if (!page || !(page.length > 0)) {
      return null;
    }

    let returnUrl: string = this.getReturnUrl(this.selectedEvent.id);
    return `${environment.EVENT_ADMIN_URL}#${page};${params}${id}=${eventId};applicationMode=SPORTSHUB;sportsHubUrl=${returnUrl};token=${token}`;
  }

  getHrefForLeaguePrivilege(privilege: Privileges, foreign?: boolean) {
    const authToken = this.store.selectSnapshot(UserState.authToken);
    const token = authToken && authToken.token ? authToken.token : null;
    let page = null;
    let params = "";
    let id = "ratingId";

    const leagueId = this.selectedLeague ? this.selectedLeague.id : null;
    if (!(leagueId > 0)) {
      return null;
    }

    if (
      privilege === Privileges.EDIT_CLUBS_FOREIGN ||
      privilege === Privileges.EDIT_CLUBS
    ) {
      page = "leagueClubs";
    } else if (
      privilege === Privileges.EDIT_COMPETITORS ||
      privilege === Privileges.EDIT_CLUB_COMPETITORS
    ) {
      page = "leagueCompetitors";
    } else if (
      privilege === Privileges.EDIT_COACHES ||
      privilege === Privileges.EDIT_CLUB_COACHES
    ) {
      page = "leagueCoaches";
    } else if (privilege === Privileges.EDIT_REGIONS) {
      page = "leagueRegions";
    } else if (privilege === Privileges.STAFF_CLUB_ADMINS) {
      page = "leagueStaffManagement";
    } else if (privilege === Privileges.LEAGUE_ID_CARDS) {
      page = "leagueIdCards";
    } else if (privilege === Privileges.LEAGUE_EVENT_WIZARD) {
      page = "eventWizard";
    } else if (privilege === Privileges.LEAGUE_SEND_CERTIFICATE) {
      page = "leagueSendCertificate";
    } else if (privilege === Privileges.LEAGUE_DOWNLOAD_CERTIFICATE) {
      page = "leagueDownloadCertificate";
    }

    if (!page || !(page.length > 0)) {
      return null;
    }

    const returnUrl = encodeURIComponent(window.location.href).replaceAll(
      "%2F",
      "___"
    );

    let result = `${environment.EVENT_ADMIN_URL}#${page};${params}${id}=${leagueId};applicationMode=SPORTSHUB;sportsHubUrl=${returnUrl};token=${token}`;

    if (privilege === Privileges.EDIT_CLUBS_FOREIGN || foreign) {
      result += ";foreign=true";
    }

    return result;
  }

  getHrefForLeagueParticipantReport() {
    const authToken = this.store.selectSnapshot(UserState.authToken);
    const token = authToken && authToken.token ? authToken.token : null;
    const leagueId = this.selectedLeague.id;

    let returnUrl: string = this.getReturnUrl(this.selectedLeague.id);
    return `${environment.EVENT_ADMIN_URL}digital/report/participation/${leagueId};applicationMode=SPORTSHUB;sportsHubUrl=${returnUrl};token=${token}`;
  }

  getRegistrationDashboardLink() {
    const authToken = this.store.selectSnapshot(UserState.authToken);
    const token = authToken && authToken.token ? authToken.token : null;
    let returnUrl: string = this.getReturnUrl(this.selectedEvent.id);
    return `${environment.EVENT_ADMIN_URL}#registration;id=${this.selectedEvent.id};applicationMode=SPORTSHUB;sportsHubUrl=${returnUrl};token=${token}`;
  }

  getReturnUrl(eventId: any) {
    let returnUrl = window.location.href;
    // if (returnUrl.indexOf(eventId) === -1) {
    //   returnUrl +=
    //     (returnUrl[returnUrl.length - 1] === "/" ? "" : "/") + eventId;
    // }
    returnUrl = encodeURIComponent(returnUrl).replaceAll("%2F", "___");

    return returnUrl;
  }

  customSearchFn(term: string, item: any) {
    return item.toLocaleLowerCase().indexOf(term) > -1;
  }

  searchEvent(stringSearch: any) {
    this.onSearchStringChange(stringSearch);
  }

  onItemSelected(selectedItem: any) {
    if (selectedItem) {
      this.loadEventData(selectedItem.id);
    } else {
      this.setSelectedEvent(null);
      this.eventRoles = [];
      this.processEventPrivileges([]);
    }
  }

  filterEvents(term: string) {
    const params = {
      page: 0,
      size: 100000,
      filter: {
        excludeIds: null,
        name: term ? term : "",
        league: "",
        dateFrom: null,
        dateTo: null,
        location: null,
      },
      sort: {
        orders: [
          {
            direction: "DESC",
            property: "eventStartDate",
          },
        ],
      },
      offset: 0,
    };

    this.eventsService.getEventsList(params).subscribe((result: any) => {
      this.events = result.items;
      this.eventsSelectorService.events$.next(this.events);
    });
  }

  onSearchStringChange(term: string) {
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      this.filterEvents(term);
    }, 500);
  }

  clearSearch() {
    this.setSelectedEvent(null);
    this.filterEvents("");
  }

  loadEventData(eventId: any) {
    this.eventsService.getEvent(eventId).subscribe((event: any) => {
      this.setSelectedEvent(event);
    });

    this.eventService.getEventRoles(eventId).subscribe((roles: Role[]) => {
      let roleName = "";
      for (const role of roles) {
        if (roleName !== "") {
          roleName += ", ";
        }
        roleName += role.name;
      }
      this.eventsSelectorService.role$.next(roleName);
      this.eventRoles = roles;
      this.processEventPrivileges(roles);

      this.loadEventConfiguration(eventId);
    });
  }

  togglePublicDraws() {
    this.eventService
      .updateEventConfigItemPublicDraws(
        this.selectedEvent.id,
        !this.isPublicDrawsEnabled
      )
      .subscribe((configItems: any) => {
        this.eventConfigItems["PUBLIC_BRACKETS_REPORT"] =
          configItems && configItems["PUBLIC_BRACKETS_REPORT"] !== undefined
            ? configItems["PUBLIC_BRACKETS_REPORT"]
            : !this.isPublicDrawsEnabled;
      });
  }

  togglePublicTimeTable() {
    this.eventService
      .updateEventConfigItemPublicTimeTable(
        this.selectedEvent.id,
        !this.isPublicTimeTableEnabled
      )
      .subscribe((configItems: any) => {
        this.eventConfigItems["PUBLIC_TIME_TABLE"] =
          configItems && configItems["PUBLIC_TIME_TABLE"] !== undefined
            ? configItems["PUBLIC_TIME_TABLE"]
            : !this.isPublicTimeTableEnabled;
      });
  }

  isLeaguePrivilegeSet(privilege: Privileges) {
    if (
      !this.selectedLeague ||
      !this.selectedLeague.privileges ||
      !(this.selectedLeague.privileges.length > 0)
    ) {
      return false;
    }

    if (this.isAdmin) {
      return true;
    }

    const privileges = this.selectedLeague.privileges;

    if (privilege === Privileges.EDIT_CLUBS_FOREIGN) {
      return (
        privileges.includes(Privileges.EDIT_CLUBS_FOREIGN) ||
        privileges.includes(Privileges.EDIT_CLUBS)
      );
    } else if (privilege === Privileges.EDIT_COMPETITORS) {
      return (
        privileges.includes(Privileges.EDIT_CLUB_COMPETITORS) ||
        privileges.includes(Privileges.EDIT_COMPETITORS)
      );
    } else if (privilege === Privileges.EDIT_COACHES) {
      return (
        privileges.includes(Privileges.EDIT_CLUB_COACHES) ||
        privileges.includes(Privileges.EDIT_COACHES)
      );
    }

    return this.selectedLeague.privileges.includes(privilege);
  }

  handleLeagueChange(league: any) {
    this.setSelectedLeague(league);
  }

  public get isLeagueSelectorAvailable() {
    return this.leagues && this.leagues.length > 1;
  }

  public get isNASKAAvailable() {
    if (this.selectedLeague) {
      return this.selectedLeague.name.toLocaleLowerCase().indexOf("naska") > -1;
    }

    return false;
  }

  doesUserHaveLeaguePrivileges(user: any) {
    let result = false;
    if (user && user.eventAccount && user.eventAccount.roleList) {
      const roleList = user.eventAccount.roleList;
      if (roleList.length > 0) {
        const length = roleList.length;
        for (let i = 0; i < length; i++) {
          const role = roleList[i];
          if (role.hasLeaguePrivileges === true 
          //  || role.isLeague === true
          ) {
            result = true;
            break;
          }
        }
      }
    }

    this.isLeaguesAvailable = result;

    return result;
  }

  getUserLeagues() {
    this.eventService.getUserLeagues().subscribe((leagues: any) => {
      let leagueId = this.route.snapshot.params["leagueId"];

      let league = leagues && leagues.length > 0 ? leagues[0] : null;
      if (leagueId && leagues) {
        league = leagues.find((league: any) => +leagueId === +league.id);
      }

      this.leagues = leagues;
      this.setSelectedLeague(league);

      leagueId = league ? league.id : null;
      this.leaguesFormGroup.get("league")?.setValue(leagueId);
    });
  }

  parseUrl(url: any) {
    if (url) {
      const parts = url
        .toLowerCase()
        .replace(/^\/+|\/+$/g, "")
        .split("/");
      if (parts && parts.length > 1 && parts[0] === "dashboard") {
        const segment = parts[1];
        if (segment === "admin") {
          this.type = DashboardType.admin;
          if (parts.length > 2) {
            const nextPart = parts[2];
            if (nextPart === "finances") {
              this.type = DashboardType.adminFinances;
            } else if (nextPart === "accounts") {
              this.type = DashboardType.adminAccounts;
            }
          }
        } else if (segment === "event") {
          this.type = DashboardType.event;
          if (parts.length > 2) {
            const lastPart = parts[parts.length - 1];

            if (lastPart === "schedule") {
              this.type = DashboardType.eventManagementSchedule;
            } else if (lastPart === "staff-management") {
              this.type = DashboardType.eventSettingsStaffManagement;
            } else if (lastPart === "finance") {
              this.type = DashboardType.eventReportsFinance;
            } else if (lastPart === "competitor") {
              this.type = DashboardType.eventReportsCompetitor;
            } else if (lastPart === "results") {
              this.type = DashboardType.eventReportsResults;
            } else if (lastPart === "school") {
              this.type = DashboardType.eventReportsSchool;
            } else if (lastPart === "medical") {
              this.type = DashboardType.eventReportsMedical;
            } else if (lastPart === "medic-info") {
              this.type = DashboardType.medical;
            } else if (lastPart === "staff") {
              this.type = DashboardType.eventReportsStaff;
            } else if (lastPart === "print") {
              this.type = DashboardType.eventReportsPrint;
            } else if (lastPart === "account-holder") {
              this.type = DashboardType.accountHolder;
            } else if (lastPart === "management") {
              this.type = DashboardType.eventManagement;
            } else if (lastPart === "reports") {
              this.type = DashboardType.eventReports;
            } else if (lastPart === "settings") {
              this.type = DashboardType.eventSettings;
            }
          }
        } else if (segment === "league") {
          this.type = DashboardType.league;
        }
      }
    }
  }

  public get isEventView() {
    return this.type === DashboardType.event;
  }

  public get isAdminViews() {
    return (
      this.type === DashboardType.admin ||
      this.type === DashboardType.adminAccounts ||
      this.type === DashboardType.adminFinances
    );
  }

  public get isAdminView() {
    return this.type === DashboardType.admin;
  }

  public get isMedicalView() {
    return this.type === DashboardType.medical;
  }

  public get isAccountsView() {
    return this.type === DashboardType.adminAccounts;
  }

  public get isFinancesView() {
    return this.type === DashboardType.adminFinances;
  }

  public get isEventViews() {
    return (
      this.isEventView ||
      this.isEventManagementView ||
      this.isEventReportsView ||
      this.isEventReportsViews ||
      this.isEventSettingsView ||
      this.isEventManagementScheduleView ||
      this.isEventSettingsStaffManagementView ||
      this.isEventAccountHolderView ||
      this.isMedicalView
    );
  }

  public get isEventManagementView() {
    return this.type === DashboardType.eventManagement;
  }

  public get isEventAccountHolderView() {
    return this.type === DashboardType.accountHolder;
  }

  public get isEventManagementViews() {
    return this.isEventManagementScheduleView;
  }

  public get isEventReportsView() {
    return this.type === DashboardType.eventReports;
  }

  public get isEventReportsViews() {
    return (
      this.isEventReportFinanceView ||
      this.isEventReportCompetitorView ||
      this.isEventReportResultsView ||
      this.isEventReportSchoolView ||
      this.isEventReportMedicalView ||
      this.isEventReportStaffView ||
      this.isEventReportPrintView
    );
  }

  public get isEventSettingsView() {
    return this.type === DashboardType.eventSettings;
  }

  public get isEventSettingsViews() {
    return this.isEventSettingsStaffManagementView;
  }

  public get isEventSettingsStaffManagementView() {
    return this.type === DashboardType.eventSettingsStaffManagement;
  }

  public get isEventManagementScheduleView() {
    return this.type === DashboardType.eventManagementSchedule;
  }

  public get isEventReportFinanceView() {
    return this.type === DashboardType.eventReportsFinance;
  }

  public get isEventReportCompetitorView() {
    return this.type === DashboardType.eventReportsCompetitor;
  }

  public get isEventReportResultsView() {
    return this.type === DashboardType.eventReportsResults;
  }

  public get isEventReportSchoolView() {
    return this.type === DashboardType.eventReportsSchool;
  }

  public get isEventReportMedicalView() {
    return this.type === DashboardType.eventReportsMedical;
  }

  public get isEventReportStaffView() {
    return this.type === DashboardType.eventReportsStaff;
  }

  public get isEventReportPrintView() {
    return this.type === DashboardType.eventReportsPrint;
  }

  public get isLeagueViews() {
    return this.isLeagueView;
  }

  public get isLeagueView() {
    return this.type === DashboardType.league;
  }

  public get isPublicDrawsEnabled() {
    return (
      this.eventConfigItems &&
      this.eventConfigItems["PUBLIC_BRACKETS_REPORT"] === true
    );
  }

  public get isPublicTimeTableEnabled() {
    return (
      this.eventConfigItems &&
      this.eventConfigItems["PUBLIC_TIME_TABLE"] === true
    );
  }

  updateBreadcrumbs() {
    const breadcrumbs = [
      {
        name: "DASHBOARD",
        url: "/dashboard",
      },
    ];

    if (this.isAdminViews) {
      breadcrumbs.push({
        name: "ROLE_ADMIN",
        url: "/dashboard/admin",
      });

      if (this.isAccountsView) {
        breadcrumbs.push({
          name: "ACCOUNTS_AND_ROLES",
          url: "/dashboard/admin/accounts",
        });
      } else if (this.isFinancesView) {
        breadcrumbs.push({
          name: "FINANCES",
          url: "/dashboard/admin/finances",
        });
      }
    } else if (this.isEventViews) {
      const selectedEventId = this.selectedEvent
        ? "/" + this.selectedEvent.id
        : "";

      breadcrumbs.push({
        name: "EVENT",
        url: "/dashboard/event" + selectedEventId,
      });

      if (this.isEventManagementView || this.isEventManagementViews) {
        breadcrumbs.push({
          name: "MANAGEMENT",
          url: `/dashboard/event${selectedEventId}/management`,
        });
      } else if (this.isEventReportsView || this.isEventReportsViews) {
        breadcrumbs.push({
          name: "REPORTS",
          url: `/dashboard/event${selectedEventId}/reports`,
        });
      } else if (this.isEventSettingsView || this.isEventSettingsViews) {
        breadcrumbs.push({
          name: "SETTINGS",
          url: `/dashboard/event${selectedEventId}/settings`,
        });
      } else if (this.isEventAccountHolderView) {
        breadcrumbs.push({
          name: "ACCOUNT_HOLDER",
          url: `/dashboard/event/account-holder`,
        });
      }
    } else if (this.isLeagueViews) {
      breadcrumbs.push({
        name: "LEAGUE",
        url: "/dashboard/league",
      });
    }

    this.breadcrumbs = breadcrumbs;
  }

  getSelectedEventIdStrVal() {
    return this.selectedEvent ? `/${this.selectedEvent.id}` : "";
  }

  setSelectedEvent(event: any) {
    this.eventsSelectorService.selectedEvent$.next(event);
    this.selectedEvent = event;
    this.store.dispatch(new SetSelectedEvent(event));
    this.updateBreadcrumbs();

    this.initializeSearchActions();

    const parts = window.location.href.split("event");
    let isChanged = false;
    if (parts.length > 1) {
      const last = parts[parts.length - 1].replace(/^\/|\/$/g, "");
      const ending = last.length > 0 ? last.split("/") : [];
      const selectedEventId = event ? +event.id : 0;
      if (ending.length > 0) {
        const segment = ending[0];
        if (+segment > 0) {
          if (selectedEventId > 0) {
            if (selectedEventId !== +segment) {
              ending[0] = "" + selectedEventId;
              isChanged = true;
            }
          } else {
            ending.shift();
            isChanged = true;
          }
        } else {
          if (selectedEventId > 0) {
            ending.unshift("" + selectedEventId);
            isChanged = true;
          }
        }
      } else {
        if (selectedEventId > 0) {
          ending[0] = "" + selectedEventId;
          isChanged = true;
        }
      }

      if (isChanged) {
        parts[parts.length - 1] = "/" + ending.join("/");
        const url = parts.join("event");
        window.history.replaceState({}, "", url);
      }
    }
  }

  setSelectedLeague(league: any) {
    this.selectedLeague = league;
    this.initializeSearchActions();
    this.updateBreadcrumbs();

    const parts = window.location.href.split("league");
    if (parts.length > 1) {
      const prefix = parts[0];
      const suffix = parts[1];
      const leagueId = league ? league.id : null;
      if (leagueId || suffix.length > 0) {
        const url = prefix + "league" + (leagueId ? "/" + leagueId : "");
        window.history.replaceState({}, "", url);
      }
    }
  }

  loadEventConfiguration(eventId: number) {
    this.eventConfigItems = {};
    this.eventStaffMap = {};

    this.eventService.getEventConfig(eventId).subscribe((items: any) => {
      this.eventConfigItems = items;
    });
    this.eventService.getEventStaffMap(eventId).subscribe((items: any) => {
      this.eventStaffMap = items;
    });
    this.eventService.getEventRings(eventId).subscribe((items: Ring[]) => {
      this.eventRings = items;
    });
  }

  handleMenuClick(action: any ) {
    if (action.click) {
      action.click();
    }
  }

  deleteAllScores() {
    if (confirm(this.transloco.translate('ARE_YOU_SURE_DELETE_ALL_SCORES').replace('{eventName}', this.selectedEvent.name))) {
      console.log("Removing all scores for the event: ", this.selectedEvent);
      this.eventsService.removeAllEventScores(this.selectedEvent.id).subscribe((result: any) => {
        this.toaster.info(this.transloco.translate('DELETE_ALL_SCORES_SCHEDULED').replace('{eventName}', this.selectedEvent.name));
      });
    }
  }

  fetchEventsData() {
    const isAdmin = this.isAdmin;

    let eventId = this.route.snapshot.params["eventId"];

    if (!(eventId > 0)) {
      eventId = this.store.selectSnapshot(UserState.selectedEventIdFromStorage);
    }

    if (isAdmin === true) {
      if (eventId > 0) {
        this.eventsService.getEvent(eventId).subscribe((event: any) => {
          if (event) {
            this.events = [event];
            this.eventsSelectorService.events$.next(this.events);
            this.setSelectedEvent(event);
            this.eventsFormGroup.controls.eventSelector.setValue(event, {
              emitEvent: false,
              onlySelf: true,
            });

            this.loadEventConfiguration(event.id);
          }
        });
      } else {
        this.filterEvents("");
        this.eventsSelectorService.selectedEvent$.next(null);
      }
    } else if (isAdmin === false) {
      this.eventService.getUserEvents().subscribe((events) => {
        this.events = events;
        this.eventsSelectorService.events$.next(this.events);
        let event = this.findClosestEvent(events);
        if (eventId > 0) {
          event = events.find((event: any) => +event.id === +eventId);
        }

        if (event) {
          this.setSelectedEvent(event);

          this.eventService
            .getEventRoles(event.id)
            .subscribe((roles: Role[]) => {
              this.eventRoles = roles;
              this.processEventPrivileges(roles);

              this.loadEventConfiguration(event.id);
            });

          this.eventsFormGroup.controls.events.setValue(event.id, {
            emitEvent: false,
            onlySelf: true,
          });
        } else {
          this.eventsSelectorService.selectedEvent$.next(null);
        }
      });
    }
  }
}
