<div class="custom-sselect" #searchDopDownList>
  <!-- Selected Item Header -->
  <div class="selected-item" (click)="toggleDropdown()">
    <div>
      <div
        class="main-title"
        [class.open]="dropdownOpen"
        [title]="selectedItem?.name"
      >
        {{ selectedItem?.name || "Select an event" }}
      </div>
      <div class="details" *ngIf="selectedItem" [class.open]="dropdownOpen">
        <span>{{ selectedItem.date }}</span>
        <span>{{ role }}</span>
      </div>
    </div>

    <img
      src="/assets/icons/chevron-down.svg"
      class="arrow"
      *ngIf="!dropdownOpen"
    />
    <img
      src="/assets/icons/chevron-up.svg"
      class="arrow"
      *ngIf="dropdownOpen"
    />
  </div>

  <!-- Dropdown Section -->
  <div class="dropdown" [class.open]="dropdownOpen">
    <input
      #searchInput
      type="text"
      class="search-input"
      placeholder="Search events..."
      [(ngModel)]="searchTerm"
      (input)="filterEvents()"
    />
    <div class="dropdown-list">
      <div
        *ngFor="let item of filteredEvents"
        class="dropdown-item"
        (click)="selectItem(item)"
        [title]="item.name"
      >
        <div class="main-title">{{ item.name }}</div>
        <div class="details">
          <span>{{ item.date }}</span>
          <span *ngIf="item.role">{{ item.role }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
