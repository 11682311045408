import {Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {ActivatedRoute, NavigationEnd, Router, RouterModule} from "@angular/router";
import {CommonModule} from "@angular/common";
import {LocalizationModule} from "../localization";
import {Logout, ToggleSidePanel} from "../../store/user/user.actions";
import {Select, Store} from "@ngxs/store";
import {filter, findIndex, first, takeUntil} from "rxjs/operators";
import {UserState} from "../../store/user/user.store";
import {SafeTypes} from "../../shared/constants/safe-types.enum";
import {IconUventex} from "../../../assets/icons/uventex";
import {IconBars} from "../../../assets/icons/bars";
import { environment } from 'src/environments/environment';
import {User} from "../../shared/models/user.model";
import {AuthTokenInfo} from "../../shared/models/auth-token.model";
import {NotificationTypes} from "../../shared/constants/notification-types.enum";
import {AuthContract} from "../../shared/contracts/auth.contract";
import {ToastService} from "../../shared/services/toast.service";
import {TranslocoService} from "@ngneat/transloco";
import { MatDialog } from '@angular/material/dialog';
import { LoginAsDialogComponent } from '../dialogs/login-as/login-as-dialog.component';
import {
  LoginByToken,
} from "../../store/user/user.actions";

@Component({
  standalone: true,
  selector: 'app-left-panel',
  templateUrl: './left-panel.component.html',
  styleUrls: ['./left-panel.component.scss'],
  imports: [
    CommonModule,
    LocalizationModule,
    RouterModule,
  ],
  providers: [
    ToastService,
  ],
})
export class LeftPanelComponent implements OnInit, OnDestroy {
  @HostBinding('class.open')
  private open: boolean = false;
  //@Input() public open!: any;

  @Output() public close = new EventEmitter<void>();

  @Select(UserState.authUser)
  public authUser$!: Observable<User>;

  @Select(UserState.isLoginAs)
  public isLoginAs$!: Observable<boolean>;
  

  user: any;

  @Select(UserState.isAdmin)
  isAdmin$!: Observable<boolean>;

  public isAdmin: boolean | null = null;

  @Select(UserState.toggleSidePanel)
  public toggleSidePanel$!: Observable<boolean>;

  private unsubscriber$ = new Subject<void>();

  @Select(UserState.selectedEvent)
  selectedEvent$!: Observable<any>;

  selectedEventId: any = null;

  public SafeTypes = SafeTypes;
  public IconUventex = IconUventex;
  public IconBars = IconBars;
  public baseUrl = 
    environment.BASEURL
      .slice(environment.BASEURL.indexOf('/')+2, -1);

  public menu: any;

  isCollapsed = false;

  isLeagueAvailable = false;

  accountHolderView = false;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private store: Store,
    private authContract: AuthContract,
    private toast: ToastService,
    private transloco: TranslocoService,
    public dialog: MatDialog,
  ){
  }

  public get isAccountHolderView() {
    return this.accountHolderView;
  }

  public async endSession(): Promise<void> {
      const authToken = await this.authContract.endSession({}).toPromise();
      if (authToken && authToken.accessToken) {
        await this.store
          .dispatch(new LoginByToken(authToken.accessToken))
          .toPromise();
      }
      this.ngOnInit();
  }

  public ngOnInit(): void {
    //this.listenNavigation();

    this.isCollapsed = false;

    this.isLeagueAvailable = false;
  
    this.accountHolderView = false;

    this.authUser$.subscribe((user) => {
      this.user = user;
      this.checkForLeaguePrivileges();
      this.initMenu();

      if (!this.isAdmin) {
        if (
          user &&
          user.eventAccount &&
          user.eventAccount.accessLevel &&
          user.eventAccount.accessLevel === "USER"
        ) {
          this.accountHolderView = true;
          this.initMenu();
        }
      }

    });

    this.isAdmin$.subscribe((isAdmin) => {
      this.isAdmin = isAdmin;
      this.initMenu();
    });

    this.toggleSidePanel$.subscribe(data => {
      this.open = data;
    });

    this.selectedEvent$.subscribe((event: any) => {
      this.selectedEventId = event ? event.id : null;
      this.initMenu();
    });
  }

  addEvent() {

    const authToken = this.store.selectSnapshot(UserState.authToken);
    const token = authToken && authToken.token ? authToken.token : null;

    let returnUrl = encodeURIComponent(window.location.href).replaceAll(
      "%2F",
      "___"
    );
    const url = `${environment.EVENT_PORTAL_URL}#proposalRequest;applicationMode=SPORTSHUB;sportsHubUrl=${returnUrl};token=${token}`;
    window.location.href = url;
  }

  manageYourAccount() {
    const authToken = this.store.selectSnapshot(UserState.authToken);
    const token = authToken && authToken.token ? authToken.token : null;

    let returnUrl = encodeURIComponent(window.location.href).replaceAll(
      "%2F",
      "___"
    );
    const url = `${environment.EVENT_PORTAL_URL}#account;applicationMode=SPORTSHUB;sportsHubUrl=${returnUrl};token=${token}`;
    window.location.href = url;
  }

  public ngOnDestroy() {}

  public openPanel() {
    this.store.dispatch(new ToggleSidePanel(true));
  }

  public closePanel() {
    this.store.dispatch(new ToggleSidePanel(false));
    this.close.emit();
  }

  private listenNavigation(): void {
    this.router.events
      .pipe(
        takeUntil(this.unsubscriber$),
        filter((event) => event instanceof NavigationEnd),
        filter(() => this.open),
      )
      .subscribe((k) => {
        this.closePanel();
      });
  }

  getFullName() {
    if (this.user) {
      return (this.user.fullName ? this.user.fullName : this.user.firstName + ' ' + this.user.lastName);
    }

    return null;
  }

  getEmail() {
    return (this.user ? this.user.email : null);
  }

  handleLoginAs() {
    const dialogRef = this.dialog.open(LoginAsDialogComponent, {
        width: "460px",
        panelClass: 'custom-dialog-container',
        data: {
          transloco: this.transloco,
        },
      }).afterClosed().pipe(first()).subscribe(v=>{
        this.ngOnInit();
      });
  }

  private initMenu() {
    this.menu = [{
      name: 'DASHBOARD',
      link: '/dashboard',
      links: ['/dashboard'],
      show: true,
      active: false,
      isExpanded: true,
      imageUrl: "/assets/img/sidebar-menu/home.svg",
      subMenu: [{
        name: 'ROLE_ADMIN',
        link: '/dashboard/admin',
        links: ['/dashboard/admin'],
        show: this.isAdminUser,
        isExpanded: true,
        subMenu: [{
          name: 'ACCOUNTS_AND_ROLES',
          link: '/dashboard/admin/accounts',
          show: true,
          imageUrl: "/assets/img/sidebar-menu/arrow-expanded.svg",
        }, {
          name: 'FINANCES',
          link: '/dashboard/admin/finances',
          show: true,
          imageUrl: "/assets/img/sidebar-menu/arrow-expanded.svg",
        }]
      }, {
        name: 'EVENT',
        link: ('/dashboard/event' + this.getSelectedEventIdStrVal()),
        links: ['/dashboard/event'],
        show: true,
        active: false,
        isExpanded: true,
        imageUrl: "/assets/icons/event-stadium.svg",

        subMenu: [
          {
            name: 'ACCOUNT_HOLDER',
            link: `/dashboard/event/account-holder`,
            show: this.isAccountHolderView,
            imageUrl: "/assets/img/sidebar-menu/arrow-expanded.svg",
          }, {
            name: 'MANAGEMENT',
            link: `/dashboard/event${this.getSelectedEventIdStrVal()}/management`,
            show: !this.isAccountHolderView,
            imageUrl: "/assets/img/sidebar-menu/arrow-expanded.svg",
          }, {
            name: 'REPORTS',
            link: `/dashboard/event${this.getSelectedEventIdStrVal()}/reports`,
            show: !this.isAccountHolderView,
            imageUrl: "/assets/img/sidebar-menu/arrow-expanded.svg",
          }, {
            name: 'SETTINGS',
            link: `/dashboard/event${this.getSelectedEventIdStrVal()}/settings`,
            show: !this.isAccountHolderView,
            imageUrl: "/assets/img/sidebar-menu/arrow-expanded.svg",
          }
        ]
      }, {
        name: 'LEAGUE',
        link: '/dashboard/league',
        show: !this.isAdminUser && this.isLeagueAvailable,
        imageUrl: "/assets/icons/dash-league-icon.svg",
      }]
    },];
  }

  handleMenuOption(m: any) {
    m.active = !m.active;

    if (m.subMenu && (m.subMenu.length > 0)) {
      m.isExpanded = !m.isExpanded;
    }
  }

  public get isAdminUser() {
    return (this.isAdmin === true);
  }

  checkForLeaguePrivileges() {
    const user = this.user;

    let result = false;
    if (user && user.eventAccount && user.eventAccount.roleList) {
      const roleList = user.eventAccount.roleList;
      if (roleList.length > 0) {
        const length = roleList.length;
        for (let i = 0; i < length; i++) {
          const role = roleList[i];
          if (role.hasLeaguePrivileges === true 
           // || role.isLeague === true
          ) {
            result = true;
            break;
          }
        }
      }
    }

    this.isLeagueAvailable = result;
  }

  getSelectedEventIdStrVal() {
    return (this.selectedEventId > 0) ? `/${this.selectedEventId}` : '';
  }

  handleLogOut() {
    this.authContract.logout().subscribe({
      next: (value: AuthTokenInfo) => {
        this.store.dispatch(new Logout());
        this.router.navigateByUrl('');
      },
      error: (error: any) => {
        const body = this.transloco.translate('ALERT_LOGOUT_FAILED');
        this.toast.show({ body, type: NotificationTypes.error });
      }
    });
  }

  expandCollapsePanel() {
    this.isCollapsed = !this.isCollapsed;
  }
}