<div class="side-panel" [ngClass]="{'collapsed': isCollapsed}">
    <div class="top-section">
        <div class="close-panel">
            <button class="close-btn" (click)="closePanel()">
                <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.5625 6L11.6875 9.15625C12.0938 9.53125 12.0938 10.1562 11.6875 10.5312L11 11.2188C10.625 11.625 10 11.625 9.625 11.2188L6.5 8.09375L3.34375 11.2188C2.96875 11.625 2.34375 11.625 1.96875 11.2188L1.28125 10.5312C0.875 10.1562 0.875 9.53125 1.28125 9.15625L4.40625 6L1.28125 2.875C0.875 2.5 0.875 1.875 1.28125 1.5L1.96875 0.8125C2.34375 0.40625 2.96875 0.40625 3.34375 0.8125L6.5 3.9375L9.625 0.8125C10 0.40625 10.625 0.40625 11 0.8125L11.6875 1.5C12.0938 1.875 12.0938 2.5 11.6875 2.875L8.5625 6Z" fill="#7A7A7A"/>
                </svg>
            </button>
        </div>

        <div class="user-details-section">
            <div *ngIf="isCollapsed" class="logo--collapsed">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                    <mask id="mask0_5019_71672" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="48" height="48">
                        <circle cx="24" cy="24" r="24" fill="#F2F5F6"/>
                    </mask>
                    <g mask="url(#mask0_5019_71672)">
                        <rect x="-0.0302734" y="-0.0913086" width="48.2896" height="47.9451" fill="#F5FBFE"/>
                        <path d="M25.4746 37.868C25.4746 39.9796 26.2408 44.7988 29.3058 48.6741" stroke="#454545" stroke-width="0.761643" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18.5786 38.6133C18.5786 40.7248 18.5787 46.0657 19.3448 50.1646" stroke="#454545" stroke-width="0.761643" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M21.2607 32.6516C28.0036 34.4402 31.2218 27.8075 33.5206 25.1991C33.5206 29.298 28.1569 41.5946 17.0464 37.8683C6.79564 34.4305 8.2346 22.9634 14.7476 14.3931C19.9581 7.53679 21.5161 3.58699 21.6438 2.46912C24.3257 7.68584 19.3451 15.8836 17.8126 18.4919C16.2801 21.1003 12.8321 30.4159 21.2607 32.6516Z" fill="#01A1DA"/>
                        <path d="M22.027 27.8072C24.1725 29.5958 27.5184 28.8009 28.9232 28.1798C27.0075 31.5334 20.1113 31.9061 18.1957 26.6893C16.6633 22.516 20.6222 16.5043 22.7932 14.0201C22.7932 15.2622 22.4101 17.7464 21.6439 19.6095C20.8142 21.6268 19.3452 25.5715 22.027 27.8072Z" fill="#BFC3C2"/>
                        <path d="M27.7734 20.3546L28.9228 18.1189" stroke="#454545" stroke-width="0.761643" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M29.6891 21.5838L31.7829 20.1343" stroke="#454545" stroke-width="0.761643" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M25.7221 19.8289L25.2387 17.3739" stroke="#454545" stroke-width="0.761643" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M26.0422 27.6294C28.1643 27.6294 29.8847 25.9562 29.8847 23.8922C29.8847 21.8282 28.1643 20.155 26.0422 20.155C23.92 20.155 22.1997 21.8282 22.1997 23.8922C22.1997 25.9562 23.92 27.6294 26.0422 27.6294Z" fill="white" stroke="#454545" stroke-width="0.761643" stroke-miterlimit="10"/>
                        <path d="M26.0199 26.0995C27.1809 26.0995 28.1221 25.1841 28.1221 24.0549C28.1221 22.9257 27.1809 22.0103 26.0199 22.0103C24.8589 22.0103 23.9177 22.9257 23.9177 24.0549C23.9177 25.1841 24.8589 26.0995 26.0199 26.0995Z" fill="#454545"/>
                        <path d="M37.8315 30.1054C39.364 31.2233 42.5822 32.7883 43.1952 30.1054C43.8082 27.4225 42.9398 22.2803 42.429 20.0446" stroke="#454545" stroke-width="0.761643"/>
                        <path d="M43.0143 18.8053C42.933 19.9998 42.7652 20.3136 42.7368 21.2292C42.606 21.04 42.476 20.8846 42.3464 20.7483C41.3801 19.7319 40.3812 17.9917 41.7289 17.6343C42.4148 17.4523 43.0472 17.7443 43.0143 18.8053Z" fill="#454545"/>
                        <path d="M8.14593 37.4195C9.37667 37.4058 9.7113 37.5442 10.6521 37.5009C10.4607 37.6482 10.3068 37.7918 10.1734 37.9335C9.22278 38.9431 7.52743 40.0675 7.045 38.7566C6.80542 38.1056 7.05577 37.4697 8.14593 37.4195Z" fill="#454545"/>
                        <path d="M9.3837 25.5714C7.5958 25.8198 4.02002 26.9129 4.02002 29.2976C4.02002 32.2786 11.6824 32.6513 12.0655 34.887C12.372 36.6756 10.4053 37.6196 9.3837 37.868" stroke="#454545" stroke-width="0.761643" stroke-linecap="round"/>
                        <path d="M27.3904 15.1851C28.3656 14.9895 30.8036 15.3025 32.7541 18.1192" stroke="#454545" stroke-width="0.761643" stroke-linecap="round"/>
                        <path d="M34.2868 30.7882C33.3673 34.0672 30.3279 36.8743 28.9231 37.868C38.4245 33.6946 40.0336 26.4409 39.6504 23.3357C35.053 24.8262 35.4361 26.6893 34.2868 30.7882Z" fill="#D3D600"/>
                    </g>
                </svg>
            </div>
            <div *ngIf="!isCollapsed" class="user-details-row">
                <div class="logo">
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                        <mask id="mask0_5019_71672" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="48" height="48">
                            <circle cx="24" cy="24" r="24" fill="#F2F5F6"/>
                        </mask>
                        <g mask="url(#mask0_5019_71672)">
                            <rect x="-0.0302734" y="-0.0913086" width="48.2896" height="47.9451" fill="#F5FBFE"/>
                            <path d="M25.4746 37.868C25.4746 39.9796 26.2408 44.7988 29.3058 48.6741" stroke="#454545" stroke-width="0.761643" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M18.5786 38.6133C18.5786 40.7248 18.5787 46.0657 19.3448 50.1646" stroke="#454545" stroke-width="0.761643" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M21.2607 32.6516C28.0036 34.4402 31.2218 27.8075 33.5206 25.1991C33.5206 29.298 28.1569 41.5946 17.0464 37.8683C6.79564 34.4305 8.2346 22.9634 14.7476 14.3931C19.9581 7.53679 21.5161 3.58699 21.6438 2.46912C24.3257 7.68584 19.3451 15.8836 17.8126 18.4919C16.2801 21.1003 12.8321 30.4159 21.2607 32.6516Z" fill="#01A1DA"/>
                            <path d="M22.027 27.8072C24.1725 29.5958 27.5184 28.8009 28.9232 28.1798C27.0075 31.5334 20.1113 31.9061 18.1957 26.6893C16.6633 22.516 20.6222 16.5043 22.7932 14.0201C22.7932 15.2622 22.4101 17.7464 21.6439 19.6095C20.8142 21.6268 19.3452 25.5715 22.027 27.8072Z" fill="#BFC3C2"/>
                            <path d="M27.7734 20.3546L28.9228 18.1189" stroke="#454545" stroke-width="0.761643" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M29.6891 21.5838L31.7829 20.1343" stroke="#454545" stroke-width="0.761643" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M25.7221 19.8289L25.2387 17.3739" stroke="#454545" stroke-width="0.761643" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M26.0422 27.6294C28.1643 27.6294 29.8847 25.9562 29.8847 23.8922C29.8847 21.8282 28.1643 20.155 26.0422 20.155C23.92 20.155 22.1997 21.8282 22.1997 23.8922C22.1997 25.9562 23.92 27.6294 26.0422 27.6294Z" fill="white" stroke="#454545" stroke-width="0.761643" stroke-miterlimit="10"/>
                            <path d="M26.0199 26.0995C27.1809 26.0995 28.1221 25.1841 28.1221 24.0549C28.1221 22.9257 27.1809 22.0103 26.0199 22.0103C24.8589 22.0103 23.9177 22.9257 23.9177 24.0549C23.9177 25.1841 24.8589 26.0995 26.0199 26.0995Z" fill="#454545"/>
                            <path d="M37.8315 30.1054C39.364 31.2233 42.5822 32.7883 43.1952 30.1054C43.8082 27.4225 42.9398 22.2803 42.429 20.0446" stroke="#454545" stroke-width="0.761643"/>
                            <path d="M43.0143 18.8053C42.933 19.9998 42.7652 20.3136 42.7368 21.2292C42.606 21.04 42.476 20.8846 42.3464 20.7483C41.3801 19.7319 40.3812 17.9917 41.7289 17.6343C42.4148 17.4523 43.0472 17.7443 43.0143 18.8053Z" fill="#454545"/>
                            <path d="M8.14593 37.4195C9.37667 37.4058 9.7113 37.5442 10.6521 37.5009C10.4607 37.6482 10.3068 37.7918 10.1734 37.9335C9.22278 38.9431 7.52743 40.0675 7.045 38.7566C6.80542 38.1056 7.05577 37.4697 8.14593 37.4195Z" fill="#454545"/>
                            <path d="M9.3837 25.5714C7.5958 25.8198 4.02002 26.9129 4.02002 29.2976C4.02002 32.2786 11.6824 32.6513 12.0655 34.887C12.372 36.6756 10.4053 37.6196 9.3837 37.868" stroke="#454545" stroke-width="0.761643" stroke-linecap="round"/>
                            <path d="M27.3904 15.1851C28.3656 14.9895 30.8036 15.3025 32.7541 18.1192" stroke="#454545" stroke-width="0.761643" stroke-linecap="round"/>
                            <path d="M34.2868 30.7882C33.3673 34.0672 30.3279 36.8743 28.9231 37.868C38.4245 33.6946 40.0336 26.4409 39.6504 23.3357C35.053 24.8262 35.4361 26.6893 34.2868 30.7882Z" fill="#D3D600"/>
                        </g>
                    </svg>
                </div>
                <div class="user-details">
                    <span class="user-name">{{ getFullName() }}</span>
                    <span *ngIf="isLoginAs$ | async" class="user-session"> <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.1719 3.97656C11.2109 4.05469 11.2305 4.15234 11.2305 4.26953C11.2305 4.36719 11.2109 4.46484 11.1719 4.54297C10.1172 6.61328 8.00781 8 5.625 8C3.22266 8 1.11328 6.61328 0.0585938 4.54297C0.0195312 4.46484 0 4.36719 0 4.25C0 4.15234 0.0195312 4.05469 0.0585938 3.97656C1.11328 1.90625 3.22266 0.5 5.625 0.5C8.00781 0.5 10.1172 1.90625 11.1719 3.97656ZM5.625 7.0625V7.08203C7.16797 7.08203 8.4375 5.8125 8.4375 4.26953V4.25C8.4375 2.70703 7.16797 1.4375 5.625 1.4375C4.0625 1.4375 2.8125 2.70703 2.8125 4.25C2.8125 5.8125 4.0625 7.0625 5.625 7.0625ZM5.625 2.375V2.39453C6.66016 2.39453 7.5 3.21484 7.5 4.25C7.5 5.28516 6.66016 6.125 5.625 6.125C4.58984 6.125 3.75 5.28516 3.75 4.25C3.75 4.09375 3.76953 3.91797 3.80859 3.76172C3.96484 3.87891 4.16016 3.9375 4.375 3.9375C4.88281 3.9375 5.29297 3.52734 5.29297 3.01953C5.29297 2.80469 5.23438 2.60938 5.11719 2.45312C5.27344 2.41406 5.44922 2.39453 5.625 2.375Z" fill="#E15F5F"/>
                        </svg>
                          {{'USER_SESSION' | transloco}}</span>
                    <span class="user-email">{{ getEmail() }}</span>
                </div>
            </div>
            <button type="button" class="expand-collapse-btn" (click)="expandCollapsePanel()" *ngIf="!isAccountHolderView">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" *ngIf="!isCollapsed">
                    <g filter="url(#filter0_b_5019_71721)">
                        <rect y="32" width="32" height="32" rx="8" transform="rotate(-90 0 32)" fill="white"/>
                        <rect y="32" width="32" height="32" rx="8" transform="rotate(-90 0 32)" fill="#01A1DA"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.811 15.9992L17.7796 19.7789C18.0733 20.059 18.0733 20.5108 17.7796 20.7909C17.6396 20.9247 17.4487 21 17.2496 21C17.0505 21 16.8596 20.9247 16.7196 20.7909L12.221 16.506C11.9263 16.2262 11.9263 15.7738 12.221 15.494L16.7194 11.2091C16.8596 11.0753 17.0505 11 17.2496 11C17.4487 11 17.6396 11.0753 17.7798 11.2091C18.0733 11.4892 18.0733 11.941 17.7794 12.2211L13.811 15.9992Z" fill="white"/>
                    </g>
                    <defs>
                        <filter id="filter0_b_5019_71721" x="-180" y="-180" width="392" height="392" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feGaussianBlur in="BackgroundImageFix" stdDeviation="90"/>
                            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_5019_71721"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_5019_71721" result="shape"/>
                        </filter>
                    </defs>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" *ngIf="isCollapsed">
                    <g filter="url(#filter0_b_5019_71190)">
                        <rect x="32" width="32" height="32" rx="8" transform="rotate(90 32 0)" fill="white"/>
                        <rect x="32" width="32" height="32" rx="8" transform="rotate(90 32 0)" fill="#01A1DA"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.189 16.0008L14.2204 12.2211C13.9267 11.941 13.9267 11.4892 14.2204 11.2091C14.3604 11.0753 14.5513 11 14.7504 11C14.9495 11 15.1404 11.0753 15.2804 11.2091L19.779 15.494C20.0737 15.7738 20.0737 16.2262 19.779 16.506L15.2806 20.7909C15.1404 20.9247 14.9495 21 14.7504 21C14.5513 21 14.3604 20.9247 14.2202 20.7909C13.9267 20.5108 13.9267 20.059 14.2206 19.7789L18.189 16.0008Z" fill="white"/>
                    </g>
                    <defs>
                        <filter id="filter0_b_5019_71190" x="-180" y="-180" width="392" height="392" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feGaussianBlur in="BackgroundImageFix" stdDeviation="90"/>
                            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_5019_71190"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_5019_71190" result="shape"/>
                        </filter>
                    </defs>
                </svg>
            </button>
        </div>

        <div class="delimiter"></div>

        <div *ngIf="isCollapsed" class="dashboard--collapsed">
            <div class="dashboard-btn">
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="48" height="48" rx="12" fill="#01A1DA" fill-opacity="0.04"/>
                    <path d="M22 31V26H26V31C26 31.55 26.45 32 27 32H30C30.55 32 31 31.55 31 31V24H32.7C33.16 24 33.38 23.43 33.03 23.13L24.67 15.6C24.29 15.26 23.71 15.26 23.33 15.6L14.97 23.13C14.63 23.43 14.84 24 15.3 24H17V31C17 31.55 17.45 32 18 32H21C21.55 32 22 31.55 22 31Z" fill="#01A1DA"/>
                </svg>
            </div>
        </div>

        <div *ngIf="!isCollapsed" class="menu">
            <ul>
                <ng-container *ngFor="let m of menu">
                    <li *ngIf="m.show">
                        <a *ngIf="!m?.subMenu?.length && m?.show !== false" routerLinkActive="active"
                           [routerLinkActiveOptions]="{exact: m?.exact}" [routerLink]="m.link"
                           (click)="closePanel()"
                        >
                            <img *ngIf="m.imageUrl" [src]="m.imageUrl" alt="{{ m.name }}" />
                            <div *ngIf="!m.imageUrl" class="no-image"></div>
                            {{ m.name | transloco }}
                        </a>

                        <a *ngIf="m?.subMenu?.length && m?.show === true" (click)="handleMenuOption(m)" (click)="closePanel()">
                            <img *ngIf="m.imageUrl" [src]="m.imageUrl" alt="{{ m.name }}" />
                            <div *ngIf="!m.imageUrl" class="no-image"></div>
                            {{ m.name | transloco }}
                        </a>

                        <ul *ngIf="m?.subMenu?.length && m?.show && m.isExpanded">
                            <ng-container *ngFor="let s1 of m?.subMenu">
                                <li *ngIf="s1.show === true">

                                    <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="s1.link" (click)="s1.isExpanded = !s1.isExpanded; closePanel();">
                                        <img *ngIf="s1.imageUrl" [src]="s1.imageUrl" alt="{{ s1.name }}" />
                                        <div *ngIf="!s1.imageUrl" class="no-image"></div>
                                        {{ s1.name | transloco }}
                                    </a>

                                    <ul *ngIf="s1?.subMenu?.length && s1.isExpanded">
                                        <ng-container *ngFor="let s2 of s1?.subMenu">
                                            <li *ngIf="s2.show === true">
                                                <a routerLinkActive="active" [routerLink]="s2.link" class="sub-menu" (click)="closePanel()">
                                                    <img *ngIf="s2.imageUrl" [src]="s2.imageUrl" alt="{{ s2.name }}" />
                                                    <div *ngIf="!s2.imageUrl" class="no-image"></div>
                                                    {{ s2.name | transloco }}
                                                </a>
                                            </li>
                                        </ng-container>
                                    </ul>
                                </li>
                            </ng-container>
                        </ul>
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>

    <div class="bottom-section" [ngClass]="{'collapsed': isCollapsed}">
        <button *ngIf="isCollapsed && !isAccountHolderView" class="login-as-btn--collapsed" (click)="handleLoginAs()">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M18.2327 9.57757C16.6636 6.51593 13.5571 4.44446 10.0001 4.44446C6.44307 4.44446 3.33572 6.51738 1.76743 9.57786C1.70124 9.70879 1.66675 9.85345 1.66675 10.0002C1.66675 10.1469 1.70124 10.2915 1.76743 10.4225C3.33659 13.4841 6.44307 15.5556 10.0001 15.5556C13.5571 15.5556 16.6644 13.4827 18.2327 10.4222C18.2989 10.2913 18.3334 10.1466 18.3334 9.99988C18.3334 9.85316 18.2989 9.7085 18.2327 9.57757ZM10.0001 14.1667C9.17599 14.1667 8.37041 13.9223 7.6852 13.4645C7 13.0066 6.46594 12.3559 6.15058 11.5945C5.83521 10.8332 5.7527 9.9954 5.91347 9.18715C6.07424 8.37889 6.47108 7.63646 7.0538 7.05374C7.63652 6.47102 8.37895 6.07418 9.1872 5.91341C9.99546 5.75264 10.8332 5.83515 11.5946 6.15052C12.356 6.46588 13.0067 6.99994 13.4645 7.68514C13.9224 8.37035 14.1668 9.17593 14.1668 10C14.167 10.5473 14.0594 11.0892 13.8501 11.5949C13.6408 12.1005 13.3339 12.5599 12.947 12.9469C12.56 13.3339 12.1006 13.6408 11.5949 13.8501C11.0893 14.0594 10.5473 14.167 10.0001 14.1667ZM10.0001 7.22224C9.75214 7.22571 9.50581 7.26259 9.26773 7.33191C9.46398 7.59859 9.55815 7.92678 9.53317 8.25695C9.50819 8.58712 9.36572 8.8974 9.13159 9.13153C8.89746 9.36566 8.58717 9.50814 8.25701 9.53311C7.92684 9.55809 7.59865 9.46392 7.33196 9.26767C7.1801 9.82716 7.20751 10.4202 7.41034 10.9633C7.61317 11.5064 7.98121 11.9722 8.46264 12.2952C8.94407 12.6181 9.51467 12.782 10.0941 12.7637C10.6736 12.7454 11.2327 12.5458 11.6928 12.1931C12.1528 11.8404 12.4907 11.3523 12.6589 10.7975C12.827 10.2427 12.817 9.64909 12.6301 9.1003C12.4432 8.55152 12.089 8.07515 11.6172 7.73825C11.1454 7.40135 10.5798 7.22088 10.0001 7.22224Z" fill="#A3A3A3"/>
            </svg>
        </button>
        <button *ngIf="isCollapsed && !isAccountHolderView" class="log-out-btn--collapsed" (click)="handleLogOut()">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M18.1028 10.5618L12.5469 16.1177C12.0509 16.6137 11.191 16.2665 11.191 15.5555V12.3807H6.69345C6.25362 12.3807 5.89976 12.0269 5.89976 11.587V8.41226C5.89976 7.97242 6.25362 7.61857 6.69345 7.61857H11.191V4.44381C11.191 3.7361 12.0476 3.38555 12.5469 3.88161L18.1028 9.43744C18.4103 9.7483 18.4103 10.251 18.1028 10.5618ZM8.01627 15.9523V14.6295C8.01627 14.4112 7.83769 14.2327 7.61942 14.2327H4.84151C4.25616 14.2327 3.78326 13.7597 3.78326 13.1744V6.82488C3.78326 6.23953 4.25616 5.76662 4.84151 5.76662H7.61942C7.83769 5.76662 8.01627 5.58804 8.01627 5.36978V4.04696C8.01627 3.8287 7.83769 3.65012 7.61942 3.65012H4.84151C3.08878 3.65012 1.66675 5.07215 1.66675 6.82488V13.1744C1.66675 14.9271 3.08878 16.3492 4.84151 16.3492H7.61942C7.83769 16.3492 8.01627 16.1706 8.01627 15.9523Z" fill="white"/>
            </svg>
        </button>
        <button *ngIf="!isCollapsed && !isAccountHolderView && isAdmin" class="login-as-btn" (click)="handleLoginAs()">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M18.2327 9.57757C16.6636 6.51593 13.5571 4.44446 10.0001 4.44446C6.44307 4.44446 3.33572 6.51738 1.76743 9.57786C1.70124 9.70879 1.66675 9.85345 1.66675 10.0002C1.66675 10.1469 1.70124 10.2915 1.76743 10.4225C3.33659 13.4841 6.44307 15.5556 10.0001 15.5556C13.5571 15.5556 16.6644 13.4827 18.2327 10.4222C18.2989 10.2913 18.3334 10.1466 18.3334 9.99988C18.3334 9.85316 18.2989 9.7085 18.2327 9.57757ZM10.0001 14.1667C9.17599 14.1667 8.37041 13.9223 7.6852 13.4645C7 13.0066 6.46594 12.3559 6.15058 11.5945C5.83521 10.8332 5.7527 9.9954 5.91347 9.18715C6.07424 8.37889 6.47108 7.63646 7.0538 7.05374C7.63652 6.47102 8.37895 6.07418 9.1872 5.91341C9.99546 5.75264 10.8332 5.83515 11.5946 6.15052C12.356 6.46588 13.0067 6.99994 13.4645 7.68514C13.9224 8.37035 14.1668 9.17593 14.1668 10C14.167 10.5473 14.0594 11.0892 13.8501 11.5949C13.6408 12.1005 13.3339 12.5599 12.947 12.9469C12.56 13.3339 12.1006 13.6408 11.5949 13.8501C11.0893 14.0594 10.5473 14.167 10.0001 14.1667ZM10.0001 7.22224C9.75214 7.22571 9.50581 7.26259 9.26773 7.33191C9.46398 7.59859 9.55815 7.92678 9.53317 8.25695C9.50819 8.58712 9.36572 8.8974 9.13159 9.13153C8.89746 9.36566 8.58717 9.50814 8.25701 9.53311C7.92684 9.55809 7.59865 9.46392 7.33196 9.26767C7.1801 9.82716 7.20751 10.4202 7.41034 10.9633C7.61317 11.5064 7.98121 11.9722 8.46264 12.2952C8.94407 12.6181 9.51467 12.782 10.0941 12.7637C10.6736 12.7454 11.2327 12.5458 11.6928 12.1931C12.1528 11.8404 12.4907 11.3523 12.6589 10.7975C12.827 10.2427 12.817 9.64909 12.6301 9.1003C12.4432 8.55152 12.089 8.07515 11.6172 7.73825C11.1454 7.40135 10.5798 7.22088 10.0001 7.22224Z" fill="#A3A3A3"/>
            </svg>
            <span>{{ 'LOGIN_AS' | transloco }}</span>
        </button>
        <button *ngIf="isLoginAs$ | async" class="login-as-btn" (click)="endSession()" class="log-out-btn">
            <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.5 14.5C19.75 14.5 20 14.75 20 15V16C20 16.2812 19.75 16.5 19.5 16.5H15V4.5H12V2.5H15.5C16.3125 2.5 17 3.21875 17 4.0625V14.5H19.5ZM9.75 0.5625C10.375 0.375 11 0.875 11 1.5625V16.5H0.5C0.21875 16.5 0 16.2812 0 16V15C0 14.75 0.21875 14.5 0.5 14.5H3V3.09375C3 2.625 3.28125 2.21875 3.75 2.09375L9.75 0.5625ZM8.25 9.5C8.65625 9.5 9 9.0625 9 8.5C9 7.96875 8.65625 7.5 8.25 7.5C7.8125 7.5 7.5 7.96875 7.5 8.5C7.5 9.0625 7.8125 9.5 8.25 9.5Z" fill="white"/>
            </svg>
            <span>{{ 'END_SESSION' | transloco }}</span>
        </button>

        <button *ngIf="!isCollapsed && !isAccountHolderView" class="log-out-btn" (click)="handleLogOut()">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M18.1028 10.5618L12.5469 16.1177C12.0509 16.6137 11.191 16.2665 11.191 15.5555V12.3807H6.69345C6.25362 12.3807 5.89976 12.0269 5.89976 11.587V8.41226C5.89976 7.97242 6.25362 7.61857 6.69345 7.61857H11.191V4.44381C11.191 3.7361 12.0476 3.38555 12.5469 3.88161L18.1028 9.43744C18.4103 9.7483 18.4103 10.251 18.1028 10.5618ZM8.01627 15.9523V14.6295C8.01627 14.4112 7.83769 14.2327 7.61942 14.2327H4.84151C4.25616 14.2327 3.78326 13.7597 3.78326 13.1744V6.82488C3.78326 6.23953 4.25616 5.76662 4.84151 5.76662H7.61942C7.83769 5.76662 8.01627 5.58804 8.01627 5.36978V4.04696C8.01627 3.8287 7.83769 3.65012 7.61942 3.65012H4.84151C3.08878 3.65012 1.66675 5.07215 1.66675 6.82488V13.1744C1.66675 14.9271 3.08878 16.3492 4.84151 16.3492H7.61942C7.83769 16.3492 8.01627 16.1706 8.01627 15.9523Z" fill="white"/>
            </svg>
            <span>{{ 'LOG_OUT' | transloco }}</span>
        </button>

        
        <button *ngIf="!isCollapsed && isAccountHolderView" class="login-as-btn" (click)="addEvent()">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 5.5C13.5312 5.5 14 5.96875 14 6.5V7.5C14 8.0625 13.5312 8.5 13 8.5H8.5V13C8.5 13.5625 8.03125 14 7.5 14H6.5C5.9375 14 5.5 13.5625 5.5 13V8.5H1C0.4375 8.5 0 8.0625 0 7.5V6.5C0 5.96875 0.4375 5.5 1 5.5H5.5V1C5.5 0.46875 5.9375 0 6.5 0H7.5C8.03125 0 8.5 0.46875 8.5 1V5.5H13Z" fill="#A3A3A3"/>
            </svg>
            <span>{{ 'ADD_EVENT' | transloco }}</span>
        </button>

        <button *ngIf="!isCollapsed && isAccountHolderView" class="login-as-btn" (click)="manageYourAccount()">
            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 8C4.78125 8 3 6.21875 3 4C3 1.8125 4.78125 0 7 0C9.1875 0 11 1.8125 11 4C11 6.21875 9.1875 8 7 8ZM9.78125 9C12.0938 9 14 10.9062 14 13.2188V14.5C14 15.3438 13.3125 16 12.5 16H1.5C0.65625 16 0 15.3438 0 14.5V13.2188C0 10.9062 1.875 9 4.1875 9H4.71875C5.40625 9.34375 6.1875 9.5 7 9.5C7.8125 9.5 8.5625 9.34375 9.25 9H9.78125Z" fill="#A3A3A3"/>
            </svg>
            <span>{{ 'MANAGE_YOUR_ACCOUNT' | transloco }}</span>
        </button>

        <button *ngIf="!isCollapsed && isAccountHolderView" routerLink="/" class="log-out-btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M18.1028 10.5618L12.5469 16.1177C12.0509 16.6137 11.191 16.2665 11.191 15.5555V12.3807H6.69345C6.25362 12.3807 5.89976 12.0269 5.89976 11.587V8.41226C5.89976 7.97242 6.25362 7.61857 6.69345 7.61857H11.191V4.44381C11.191 3.7361 12.0476 3.38555 12.5469 3.88161L18.1028 9.43744C18.4103 9.7483 18.4103 10.251 18.1028 10.5618ZM8.01627 15.9523V14.6295C8.01627 14.4112 7.83769 14.2327 7.61942 14.2327H4.84151C4.25616 14.2327 3.78326 13.7597 3.78326 13.1744V6.82488C3.78326 6.23953 4.25616 5.76662 4.84151 5.76662H7.61942C7.83769 5.76662 8.01627 5.58804 8.01627 5.36978V4.04696C8.01627 3.8287 7.83769 3.65012 7.61942 3.65012H4.84151C3.08878 3.65012 1.66675 5.07215 1.66675 6.82488V13.1744C1.66675 14.9271 3.08878 16.3492 4.84151 16.3492H7.61942C7.83769 16.3492 8.01627 16.1706 8.01627 15.9523Z" fill="white"/>
            </svg>
            <span>{{ 'EXIT_DASHBOARD' | transloco }}</span>
        </button>
    </div>
</div>