import { fromEvent, Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

export const isOwnClick = (clickOwnerElement: HTMLElement, skipExcludes: HTMLElement[] = []): Observable<boolean> => {
  return fromEvent(document, 'click').pipe(
    filter((event: Event) => {
      const targetElement = event.target as HTMLElement;

      if (!targetElement) {
        return true;
      }

      return !skipExcludes.some((skipExclude) => typeof skipExclude.contains === 'function' && skipExclude.contains(targetElement));
    }),
    map((event: Event) => {
      const targetElement = event.target as HTMLElement;

      if (!targetElement) {
        return true;
      }

      if (clickOwnerElement === targetElement || clickOwnerElement.contains(targetElement)) {
        return true;
      }

      return false;
    }),
  );
};
