<div class="cont">
  <div class="header">
    <h2 mat-dialog-title class="title">Medical note</h2>
    <button
      class="close-button"
      mat-icon-button
      (click)="closeDialog()"
      aria-label="Close"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content class="c-body">
    <span class="c-label">Competitor</span>
    <mat-form-field appearance="fill" class="full-width">
      <input class="custom-placeholder-color" matInput placeholder="Type competitor name..." />
    </mat-form-field>

    <span class="c-label">Division</span>
    <mat-form-field appearance="fill" class="full-width">
      <input class="custom-placeholder-color" matInput placeholder="Type division code or name..." />
    </mat-form-field>

    <span class="c-label">Division</span>
    <mat-radio-group class="full-width c-radio">
      <mat-radio-button class="no-pad" value="concussion">Concussion</mat-radio-button>
      <mat-radio-button class="no-pad" value="other">Other - please specify</mat-radio-button>
    </mat-radio-group>

    <mat-form-field appearance="fill" class="full-width">
      <input class="custom-placeholder-color" matInput placeholder="Start typing..." />
    </mat-form-field>

    <span class="c-label">Additional notes</span>
    <textarea matInput placeholder="Start typing..." class="t-area"></textarea>
  </div>
  <div mat-dialog-actions>
    <div class="bottom">
      <button class="action-b">Add note</button>
    </div>
  </div>
</div>
