<div class="cont">
  <div class="header">
    <h2 mat-dialog-title class="title">Call medic</h2>
    <button
      class="close-button"
      mat-icon-button
      (click)="closeDialog()"
      aria-label="Close"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div mat-dialog-content class="c-body">
            <span class="c-label">Notify</span>
            <mat-form-field appearance="fill">
                <mat-select formControlName="medic">
                    <mat-option *ngFor="let option of medicsListOptions" [value]="option.personId">
                        {{ option.name + (option.email ? ' <' + option.email + '>' : '') }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <span class="c-label">Areas</span>
            <mat-form-field appearance="fill">
                <mat-select formControlName="area" (selectionChange)="onRingChanged()" [(value)]="selectedRingId" >
                    <mat-option *ngFor="let area of eventRings" [value]="area.id">
                        {{ area.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <span class="c-label">Message</span>
            <textarea matInput formControlName="message" placeholder="Start typing..." class="t-area"></textarea>
        </div>
        <div mat-dialog-actions>
            <div class="bottom">
                <button class="action-b">Send to medic</button>
            </div>
        </div>
    </form>

</div>
