import {Component, Inject} from "@angular/core";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatListModule} from "@angular/material/list";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {NgFor} from "@angular/common";
import {MatCardModule} from "@angular/material/card";
import {MatSelectModule} from "@angular/material/select";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators,} from "@angular/forms";
import {LocalizationModule} from "src/app/components/localization";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatIconModule} from "@angular/material/icon";
import {MedicPerson} from "../../shared/models/events.model";
import {Ring} from "../../shared/models/rings.model";
import {EventService} from "../../shared/services/event.service";
import {ToastrService} from "ngx-toastr";
import {TranslocoService} from "@ngneat/transloco";

@Component({
  selector: "app-call-medic-dialog",
  templateUrl: "./call-medic-dialog.component.html",
  standalone: true,
  imports: [
    MatSidenavModule,
    NgFor,
    MatSidenavModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    NgFor,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatSelectModule,
    LocalizationModule,
  ],
  styleUrls: ["./call-medic-dialog.component.scss"],
})
export class CallMedicDialogComponent {

  medicsListOptions: MedicPerson[] = [];

  eventRings: Ring[] = [];

  selectedRingId: any;

  eventId: any;

  form: FormGroup;

  transloco: TranslocoService | null = null;

  success: boolean = false;

  public interestOptions = [
    { id: "athleteTracking", label: "Athlete tracking and registration" },
    { id: "studioManagement", label: "Studio management" },
    { id: "eventManagement", label: "Event management" },
    { id: "memberships", label: "Memberships" },
    { id: "federation", label: "Federation or franchise management" },
    { id: "ticketSales", label: "Ticket sales" },
    { id: "banquetsAndSeminars", label: "Banquets and seminars" },
  ];

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CallMedicDialogComponent>,
    private eventService: EventService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.form = this.fb.group({
      medic: ["", [Validators.required]],
      area: ["", [Validators.required]],
      message: ["", Validators.required],
    });

    this.medicsListOptions = data.medicsListOptions;
    this.eventRings = data.eventRings;
    this.eventId = data.eventId;
    this.transloco = data.transloco;
  }

  onSubmit() {
    if (this.form.valid) {
      // Process form data here

      let medicData = {
        personId: this.form.value.medic,
        ringId: this.form.value.area,
        message: this.form.value.message,
        eventId: this.eventId,
      };
      this.success = true;

      // this.form.get("submitButton")?.disable();
      this.eventService.callMedic(this.eventId, medicData).subscribe(
        (userData) => {
          this.toastr.success(this.transloco?.translate("CALL_MEDICS_SUCCESS"));
          console.log("Call Medic Request sent: ", userData);
          this.closeDialog();
        },
        (error) => {
          const message = error.error.message;
          this.toastr.warning(message, this.transloco?.translate("CALL_MEDICS_FAILED"), { closeButton: true });
        },
      );
    } else {
      // Mark all fields as touched to show errors
      this.form.markAllAsTouched();
    }
  }

  isFieldInvalid(field: string): boolean | undefined {
    return (
      this.form.get(field)?.invalid &&
      (this.form.get(field)?.touched || this.form.get(field)?.dirty)
    );
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onRingChanged() {
    console.log("Selected Ring: ", this.selectedRingId);
    if (this.selectedRingId) {
      for (let i = 0; i < this.eventRings.length; i++) {
        let ring = this.eventRings[i];
        if (ring.id === this.selectedRingId) {
          let currentMessage = this.transloco?.translate("CALL_MEDICS_MESSAGE_TEMPLATE").replace("%s", ring.name);
          this.form.get("message")?.setValue(currentMessage);
          break;
        }
      }
    }
  }
}
