export enum Privileges {
  EVENT_DELETE_ALL_SCORES = 'EVENT_DELETE_ALL_SCORES',
  SCOREKEEPER = 'SCOREKEEPER',
  JUDGE = 'JUDGE',
  REGISTRATION = 'REGISTRATION',
  CHECK_IN = 'CHECK_IN',
  STAFF_MANAGEMENT = 'STAFF_MANAGEMENT',
  ROLES_APPROVAL = 'ROLES_APPROVAL',
  PRICING_PLAN = 'PRICING_PLAN',
  EDIT_DIVISIONS = 'EDIT_DIVISIONS',
  MANUAL_STANDING = 'MANUAL_STANDING',
  MERGE_PERSON = 'MERGE_PERSON',
  DISCOUNT_CODE = 'DISCOUNT_CODE',
  RINGS = 'RINGS',
  PRINT_ENVELOPES = 'PRINT_ENVELOPES',
  EVENT_PARTICIPANT = 'EVENT_PARTICIPANT',
  PAYMENT_LIST = 'PAYMENT_LIST',
  FINANCIAL_REPORT = 'FINANCIAL_REPORT',
  FINANCIAL_REPORT_SUPER_EVENT = 'FINANCIAL_REPORT_SUPER_EVENT',
  STANDING_REPORT = 'STANDING_REPORT',
  COMPETITOR_REPORT = 'COMPETITOR_REPORT',
  MERCHANDISE_REPORT = 'MERCHANDISE_REPORT',
  DRAG_N_DROP = 'DRAG_N_DROP',
  SCOREBOARD_EDIT = 'SCOREBOARD_EDIT',
  FIND_ME = 'FIND_ME',
  RINGS_REPORTS = 'RINGS_REPORTS',
  EVENT_STREAMING = 'EVENT_STREAMING',
  SEND_EMAILS = 'SEND_EMAILS',
  TOURNAMENT_PERFORMANCE = 'TOURNAMENT_PERFORMANCE',
  SCHOOL_OWNER = 'SCHOOL_OWNER',
  RATINGS_INFO = 'RATINGS_INFO',
  EVENT_EDITOR = 'EVENT_EDITOR',
  PROMOTER_INVOICE = 'PROMOTER_INVOICE',
  ADDED_COMPETITORS = 'ADDED_COMPETITORS',
  AMBER_ALERT = 'AMBER_ALERT',
  QUESTION_CONSTRUCTOR = 'QUESTION_CONSTRUCTOR',
  EDIT_BANQUET = 'EDIT_BANQUET',
  HELP_FILES_CONSTRUCTOR = 'HELP_FILES_CONSTRUCTOR',
  CERTIFICATION_REPORT = 'CERTIFICATION_REPORT',
  SCHOOLS_REPORT = 'SCHOOLS_REPORT',
  RESTRICTIONS_REPORT = 'RESTRICTIONS_REPORT',
  DIVISIONS_PRIZES_REPORT = 'DIVISIONS_PRIZES_REPORT',
  STAFF_REPORT = 'STAFF_REPORT',
  SEATING_CHART = 'SEATING_CHART',
  MEDICAL_FORM = 'MEDICAL_FORM',
  STAFF_ACTIVITY = 'STAFF_ACTIVITY',
  NOMINEE_REPORT = 'NOMINEE_REPORT',
  FIND_STANDING = 'FIND_STANDING',
  MEDIC = 'MEDIC',
  CALL_MEDIC = 'CALL_MEDIC',
  MEDICAL_REPORT = 'MEDICAL_REPORT',
  RESHUFFLE_UNLOCK = 'RESHUFFLE_UNLOCK',
  RING_SCORING = 'RING_SCORING',
  POINT_CORRECTION = 'POINT_CORRECTION',
  AUTHORIZED_SCHOOL_OWNER = 'AUTHORIZED_SCHOOL_OWNER',
  DEMO_USER = 'DEMO_USER',
  SELL_TICKETS = 'SELL_TICKETS',
  GEO_REPORT = 'GEO_REPORT',
  MEMBERSHIP_REPORT = 'MEMBERSHIP_REPORT',
  STAFF_QR_CODES = 'STAFF_QR_CODES',
  OFFLINE_SERVER_EDITOR = 'OFFLINE_SERVER_EDITOR',
  RESERVED_59 = 'RESERVED_59',
  RESERVED_60 = 'RESERVED_60',
  CHIEF_JUDGE = 'CHIEF_JUDGE',
  START_TIME = 'START_TIME',
  FACEBOOK_PAGE = 'FACEBOOK_PAGE',
  YOUTUBE_PAGE = 'YOUTUBE_PAGE',
  EDIT_REGIONS = 'EDIT_REGIONS',
  EDIT_CLUBS = 'EDIT_CLUBS',
  EDIT_COMPETITORS = 'EDIT_COMPETITORS',
  EDIT_COACHES = 'EDIT_COACHES',
  EDIT_REFEREES = 'EDIT_REFEREES',
  EDIT_OFFICIALS = 'EDIT_OFFICIALS',
  STAFF_MODERATORS = 'STAFF_MODERATORS',
  STAFF_COACH_ADMINS = 'STAFF_COACH_ADMINS',
  STAFF_REFEREE_ADMINS = 'STAFF_REFEREE_ADMINS',
  STAFF_ATTESTATION_ADMINS = 'STAFF_ATTESTATION_ADMINS',
  STAFF_REGION_ADMINS = 'STAFF_REGION_ADMINS',
  STAFF_CLUB_ADMINS = 'STAFF_CLUB_ADMINS',
  EDIT_LICENSES_COACH = 'EDIT_LICENSES_COACH',
  EDIT_LICENSES_REFEREE = 'EDIT_LICENSES_REFEREE',
  EDIT_ATTESTATION_COMPETITOR = 'EDIT_ATTESTATION_COMPETITOR',
  VIEW_REGION_CLUBS = 'VIEW_REGION_CLUBS',
  VIEW_REGION_COMPETITORS = 'VIEW_REGION_COMPETITORS',
  REGISTER_REGION = 'REGISTER_REGION',
  EDIT_CLUB_COMPETITORS = 'EDIT_CLUB_COMPETITORS',
  EDIT_CLUB_COACHES = 'EDIT_CLUB_COACHES',
  EDIT_CLUB_REFEREES = 'EDIT_CLUB_REFEREES',
  EDIT_CLUB_OFFICIALS = 'EDIT_CLUB_OFFICIALS',
  REGISTER_CLUB = 'REGISTER_CLUB',
  EDIT_CLUBS_FOREIGN = 'EDIT_CLUBS_FOREIGN',
  MUSIC_REPORT = 'MUSIC_REPORT',
  FLOWS = 'FLOWS',
  BUILD_DRAWS = 'BUILD_DRAWS',
  MEDALS_REPORT = 'MEDALS_REPORT',
  ACCREDITATION_CARDS = 'ACCREDITATION_CARDS',
  LIMITS_REPORT = 'LIMITS_REPORT',
  OFFICIALS_REPORT = 'OFFICIALS_REPORT',
  TRAVEL_REPORT = 'TRAVEL_REPORT',
  PARTICIPANT_REPORT = 'PARTICIPANT_REPORT',
  LEAGUE_ID_CARDS = 'LEAGUE_ID_CARDS',
  CLUB_PARTICIPANT = 'CLUB_PARTICIPANT',
  PAYMENT_METHOD = 'PAYMENT_METHOD',
  LEAGUE_API = 'LEAGUE_API',
  EVENT_API = 'EVENT_API',
  EDIT_INTERNATIONAL_REFEREES = 'EDIT_INTERNATIONAL_REFEREES',
  EDIT_CONTINENTAL_REFEREES = 'EDIT_CONTINENTAL_REFEREES',
  REFEREE = 'REFEREE',
  RESET_RESULTS = 'RESET_RESULTS',
  CHANGE_ELIMINATION = 'CHANGE_ELIMINATION',
  REGISTRATION_EXTENDED = 'REGISTRATION_EXTENDED',
  REGISTRATION_REFUND = 'REGISTRATION_REFUND',
  ANNOUNCER = 'ANNOUNCER',
  EDIT_NATIONAL_FEDERATIONS = 'EDIT_NATIONAL_FEDERATIONS',
  VIEW_NATIONAL_FEDERATIONS = 'VIEW_NATIONAL_FEDERATIONS',
  ADMIN_EVENT_WIZARD = 'ADMIN_EVENT_WIZARD',
  LEAGUE_EVENT_WIZARD = 'LEAGUE_EVENT_WIZARD',
  PARTIAL_PAYMENT_REPORT = 'PARTIAL_PAYMENT_REPORT',
  SHOW_DRAWS = 'SHOW_DRAWS',
  APPROVED_EVENT_WIZARD = 'APPROVED_EVENT_WIZARD',
  SEND_TICKETS = 'SEND_TICKETS',
  ADMIN_SCHOOLS = 'ADMIN_SCHOOLS',
  ADMIN_SCOREBOARD_CONFIGS = 'ADMIN_SCOREBOARD_CONFIGS',
  ADMIN_MERCHANDISE = 'ADMIN_MERCHANDISE',
  ADMIN_RATINGS = 'ADMIN_RATINGS',
  VIEW_CONTACT_INFO = 'VIEW_CONTACT_INFO',
  ASSIGN_PROMOTERS = 'ASSIGN_PROMOTERS',
  DOWNLOAD_REPORTS = 'DOWNLOAD_REPORTS',
  EVENT_DATA = 'EVENT_DATA',
  PREPARATION_SCREEN = 'PREPARATION_SCREEN',
  SEND_LINKS = 'SEND_LINKS',
  VERIFY_COMPETITORS = 'VERIFY_COMPETITORS',
  SHOW_SCORES = 'SHOW_SCORES',
  EVENT_IMAGES = 'EVENT_IMAGES',
  LEAGUE_EMAILS = 'LEAGUE_EMAILS',
  BUILD_FINALS = 'BUILD_FINALS',
  LEAGUE_SEND_CERTIFICATE = 'LEAGUE_SEND_CERTIFICATE',
  LEAGUE_DOWNLOAD_CERTIFICATE = 'LEAGUE_DOWNLOAD_CERTIFICATE',
  UVENTEX_TV = 'UVENTEX_TV',
  ADMIN_UVENTEX_TV = 'ADMIN_UVENTEX_TV',
  EDIT_CLUB_COMPETITOR_STATUS = 'EDIT_CLUB_COMPETITOR_STATUS',
  EDIT_COMPETITOR_TITLE = 'EDIT_COMPETITOR_TITLE',
  LEAGUE_PARTICIPATION_REPORT = 'LEAGUE_PARTICIPATION_REPORT',
  PUBLIC_TIME_TABLE = 'PUBLIC_TIME_TABLE',
  CHECK_IN_TIME_TABLE = 'CHECK_IN_TIME_TABLE',
  BANQUET_TABLES_REPORT = 'BANQUET_TABLES_REPORT',
  AFFILIATES_REPORT = 'AFFILIATES_REPORT',
}

export enum DashboardAdminOptions {
  zoomMeetings = 'zoomMeetings',
  createEvent = 'createEvent',
  eventWizard = 'eventWizard',
  adminScoreboardConfig = 'adminScoreboardConfig',
  merchandise = 'merchandise',
  leagueEmails = 'leagueEmails',
  liveStreamToken = 'liveStreamToken',
  ads = 'ads',
  adminDemoPage = 'adminDemoPage',
  helpFilesConstructor = 'helpFilesConstructor',
  adminRatings = 'adminRatings',
  leaguePages = 'leaguePages',
  adminEntity = 'adminEntity',
  events = 'events',
  searchRegistration = 'searchRegistration',
  myResults = 'myResults',
  adminRolesApproval = 'adminRolesApproval',
  adminRoles = 'adminRoles',
  adminEventPromoter = 'adminEventPromoter',
  mergePerson = 'mergePerson',
  adminPasswordReset = 'adminPasswordReset',
  adminUserSession = 'adminUserSession',
  adminPromoterAccounts = 'adminPromoterAccounts',
  affiliateReport = 'affiliateReport',
  paymentMethodReport = 'paymentMethodReport',
  billing = 'billing',
  adminBalance = 'adminBalance',
  adminPromoterInvoice = 'adminPromoterInvoice',
  adminInvoiceManagement = 'adminInvoiceManagement',
  adminAllSchools = 'adminAllSchools',
}